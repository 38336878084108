<template>
    <div>
        <!-- <CreateProject /> -->
        <DrawerCreate />
        <CreateProjectTemplate />
        <MainPage isProject />
    </div>
</template>

<script>
import store from "./store/index"
import CreateProject from './CreateProject'
import CreateProjectTemplate from './CreateProjectTemplate'
import DrawerCreate from "./ProjectCreate.vue"
import MainPage from './MainPage/index.vue'
export default {
    name: "ProjectsInit",
    components: {
        DrawerCreate,
        // CreateProject,
        CreateProjectTemplate,
        MainPage
    },
    data() {
        return {
            buttonSize: 'large'
        }
    },
    created() {
        if(!this.$store.hasModule('projects')) {
            this.$store.registerModule("projects", store)
            this.$store.commit('ADD_CONNECTED_MODULES', 'projects')
        }
    }
}
</script>