<template>
    <Drawer
        v-model="visible"
        :title="$t('wgr.project_templates')"
        width="80%"
        :class="isMobile && 'mobile'"
        class="drawer"
        destroyOnClose
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <div slot="body">
            <a-skeleton active :paragraph="{ rows: 4 }" :loading="loading">
                <div class="flex">
                    <div class="w-full max-w-[340px] mr-6">
                        <ProjectTemplateCreate class="mb-8" />
                        <TemplateList 
                            class=""
                            v-model="form"
                            :activeItem="selectedProjectTemplate"
                            :selectHandler="selectProjectTemplate" />
                    </div>
                    <div class="flex-grow">
                        <UniversalTable 
                            ref="universeTableRef"
                            :model="pageModel"
                            :pageName="page_name"
                            :tableType="tableType"
                            :endpoint="endpoint"
                            :showChildren="true"/>
                        <!-- Создать шаблон -->
                        <div class="mt-4 flex items-center">
                            <div>
                                <a-tooltip :title="!selectedProjectTemplate && $t('wgr.select_project_template')">
                                    <a-button type="link" class="px-1" @click="openStageCreate" :disabled="!selectedProjectTemplate">Добавить этап</a-button>
                                </a-tooltip>
                                <template v-if="selectedProjectTemplate">
                                    <StageCreate 
                                        ref="stageCreateRef" 
                                        @created="reloadTable"
                                        :template="selectedProjectTemplate" />
                                </template>
                                <!-- <span>/</span>
                                <a-button type="link" class="px-1">Добавить задачу</a-button>
                                <span>/</span>
                                <a-button type="link" class="px-1">Добавить веху</a-button> -->
                            </div>
                            <a-button 
                                size="large" 
                                type="primary" 
                                class="ml-auto"
                                :loading="loadingBtn"
                                :disabled="!selectedProjectTemplate"
                                @click="saveTemplate">
                                {{ $t('wgr.save_template') }}
                            </a-button>
                        </div>
                    </div>
                </div>
            </a-skeleton>
        </div>
    </Drawer>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/ru_RU";
import Drawer from "../widgets/DrawerTemplate";
import createdMethods from "../mixins/createdMethods";
import eventBus from "@/utils/eventBus";
import IMask from "imask";
import UniversalTable from '@/components/TableWidgets/UniversalTable'
import TemplateList from '../components/ProjectTemplates/TemplateList.vue'
import ProjectTemplateCreate from '../components/ProjectTemplates/ProjectTemplateCreate.vue'
import StageCreate from '../components/ProjectTemplates/StageCreate.vue'


import "cropperjs/dist/cropper.css";
export default {
    name: "GroupsAndProjectCreateProject",
    mixins: [createdMethods],
    components: {
        Drawer,
        UniversalTable,
        TemplateList,
        ProjectTemplateCreate,
        StageCreate
    },
    props: {
        pageName: {
            type: String,
            default: "page_list_project_workgroups.WorkgroupModel",
        },
    },
    data() {
        return {
            // table
            page_name: 'tasks.groups_and_project_3a99bfc8-7cb3-11ef-972c-c59d62e270f9',
            tableType: 'project_stages',
            pageModel: 'ProjectStageTemplateModel',

            selectedProjectTemplate: null,

            form: {
                isPublic: false,
                organization: null,
                name: ''
            },

            dateFormat: "YYYY-MM-DD HH:mm",
            isPublic: false,

            visible: false,
            loading: false,
            locale,
            previewFile: null,
            edit: false,
            groupTypes: [],
            sLinks: [],
            listLinks: [],
            endpoint: null,

            loadingBtn: false,
        };
    },
    watch: {
    
        "$route.query": {
            immediate: true,
            handler() {
                this.getParamsQuerySet();
            }            
        },
    },
    computed: {
        id() {
            return this.$route.query.updateProject;
        },
        isMobile() {
            return this.$store.state.isMobile;
        },
        tableRows() { 
            return this.$store.state.table?.tableRows?.[this.pageModel+this.page_name] || []
        }

    },
    created() {
        if (this.selectedProjectTemplate) {
            this.endpoint = `/work_groups/stage_templates/?template=${this.selectedProjectTemplate}`
        }
    },
    mounted() {
        eventBus.$on("open_create_project_drawer", ({ organization = null }) => {
            this.$router.replace({
                query: { createProject: true },
            });

            this.form.organization = organization;
            this.getParamsQuerySet();
        });
    },
    beforeDestroy() {
        eventBus.$off("open_create_project_drawer");
    },
    methods: {
        openStageCreate() {
            if (!this.selectedProjectTemplate) {
                this.$message.error(this.$t('wgt.select_project_template'))
                return;
            }
            this.$refs.stageCreateRef.open()
        },
        selectProjectTemplate(id) {
            if (this.selectedProjectTemplate === id) {
                this.selectedProjectTemplate = null;
                this.endpoint = null
                
                this.reloadTable()
                return;
            }
            this.selectedProjectTemplate = id;
            this.endpoint = `/work_groups/stage_templates/?template=${this.selectedProjectTemplate}`

            this.reloadTable()
        },
        reloadTable() {
            if (this.$refs.universeTableRef) {
                this.$nextTick().then(() => {
                    this.$refs?.universeTableRef?.reloadTableData()
                })
            }
        },
        afterVisibleChange(vis) {
            if (!vis) {
                this.close()
            }
        },
        getParamsQuerySet() {
            const query = Object.assign({}, this.$route.query);

            if (query.hasOwnProperty("createProjectTemplate")) {
                this.init();
            }
            if (query.updateProject) {
                this.init();
                this.initUpdate();
            }
        },
        saveTemplate() {
            const rows = this.tableRows.filter(row => row.new)
            this.loadingBtn = true
            Promise.all(rows.map(row => this.createTask(row)))
                .then(() => {
                    this.reloadTable()
                })
                .catch(error => {
                    // TODO: ПЕРЕВОДЫ
                    if (error.cause === 'empty') {
                        this.$message.error('Заполните обязательные поля')
                    } else {
                        this.$message.error('Не удалось создать задачу')
                    }
                    console.error(error)
                })
                .finally(() => {
                    this.loadingBtn = false
                })
        },
        createTask(record) {
            const payload = {
                project_stage: record.parent_expand,
                name: record.name,
                description: record.description,
                task_type: record.task_type
            }
            if (record.task_type === 'task') {
                payload.duration = `${record.duration} 00:00:00`
            }

            if (!record.name || !record.duration && record.task_type === 'task') {
                return Promise.reject(new Error('Не заполнены обязательные поля', { cause: 'empty' } ))
            }

            return this.$http.post('/work_groups/task_templates/', payload)
                .then(({ data }) => {
                    this.$message.success(`Задача "${data.name}"" создана`)
                })
                .catch((error) => { 
                    throw error
                })
        },
        close() {
            const query = Object.assign({}, this.$route.query);
            delete query["createProjectTemplate"];
            if (query.updateProject) {
                const viewGroup = query.updateProject;
                delete query["updateProject"];
                query.viewGroup = viewGroup;
            }
            this.edit = false;
            this.$router.replace({ query });
        },
    },
};
</script>
