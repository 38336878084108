<template>
    <a-drawer
        :visible="visible"
        @close="visible = false"
        class="task_edit"
        :width="drawerWidth"
        :zIndex="999999"
        :title="edit ? 'Редактировать спринт' : 'Создать спринт'"
        :after-visible-change="afterVisibleChange">
        <div ref="sprintFormWrapper" class="d_body"> 
            <a-spin :spinning="sprintLoading" class="w-full">
                <a-form-model
                    ref="sprintForm"
                    :model="form"
                    :rules="rules">
                    <a-form-model-item
                        ref="name"
                        label="Название спринта"
                        prop="name">
                        <a-input
                            v-model="form.name"
                            size="large"
                            placeholder="Полное наименование спринта" />
                    </a-form-model-item>
                    <a-form-model-item
                        ref="time_interval"
                        label="Длительность"
                        prop="time_interval">
                        <a-select 
                            v-model="form.time_interval" 
                            :getPopupContainer="getPopupContainer"
                            size="large">
                            <a-select-option value="week">
                                Неделя
                            </a-select-option>
                            <a-select-option value="two_week">
                                Две недели
                            </a-select-option>
                            <a-select-option value="month">
                                Месяц
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <div class="grid gap-4 grid-cols-2">
                        <a-form-model-item
                            ref="date_1"
                            label="Дата начала"
                            prop="date_1">
                            <a-date-picker 
                                v-model="form.date_1"
                                class="w-full"
                                :showToday="false"
                                :getCalendarContainer="getPopupContainer"
                                placeholder="Выбрать дату"
                                size="large" />
                        </a-form-model-item>
                        <a-form-model-item
                            ref="date_2"
                            label="Дата завершения"
                            prop="date_2">
                            <a-date-picker 
                                v-model="form.date_2"
                                :showToday="false"
                                :getCalendarContainer="getPopupContainer"
                                class="w-full"
                                placeholder="Выбрать дату"
                                size="large" />
                        </a-form-model-item>
                    </div>
                    <a-form-model-item
                        ref="target"
                        label="Цель спринта"
                        prop="target">
                        <a-input
                            v-model="form.target"
                            size="large"
                            placeholder="Введите цель спринта" />
                    </a-form-model-item>
                    <a-form-model-item
                        ref="expected_result"
                        label="Ожидаемый результат"
                        prop="expected_result">
                        <a-select 
                            v-model="form.expected_result" 
                            mode="tags" 
                            class="w-full"
                            size="large" 
                            :getPopupContainer="getPopupContainer"
                            placeholder="Введите ожидаемый результат" 
                            notFoundContent="Введите несколько значений">
                        </a-select>
                    </a-form-model-item>
                    <a-button 
                        type="primary" 
                        size="large" 
                        :loading="loading"
                        block
                        @click="formSubmit()">
                        <template v-if="edit">
                            Сохранить
                        </template>
                        <template v-else>
                            Добавить спринт
                        </template>
                    </a-button>
                </a-form-model>
            </a-spin>
        </div>
    </a-drawer> 
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    data(){
        return {
            edit: false,
            visible: false,
            sprintLoading: false,
            form: {
                name: "",
                target: "",
                time_interval: null,
                expected_result: [],
                date_1: null,
                date_2: null
            },
            rules: {
                name: [
                    { required: true, message: this.$t('task.field_require'), trigger: 'blur' },
                    { max: 255, message: this.$t('task.field_min_require'), trigger: 'blur' }
                ],
                date_1: [
                    { required: true, message: this.$t('task.field_require'), trigger: 'blur' }
                ],
                date_2: [
                    { required: true, message: this.$t('task.field_require'), trigger: 'blur' }
                ],
                time_interval: [
                    { required: true, message: this.$t('task.field_require'), trigger: 'blur' }
                ]
            },
            loading: false
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        drawerWidth() {
            if(this.windowWidth > 1200)
                return 821
            else if(this.windowWidth <= 1200 && this.windowWidth > 821) {
                return '95%'
            } else {
                return '100%'
            }
        },
    },
    methods: {
        getPopupContainer() {
            return this.$refs.sprintFormWrapper
        },
        formSubmit() {
            this.$refs.sprintForm.validate(async valid => {
                if (valid) {
                    const queryData = {...this.form}
                    if(this.edit) {
                        try {
                            this.loading = true
                            const { data } = await this.$http.put(`/tasks/sprint/${queryData.id}/update/`, queryData)
                            if(data) {
                                this.$message.success("Спринт обновлен")
                                eventBus.$emit('update_filter_tasks.TaskSprintModel')
                                this.visible = false
                            }
                        } catch(e) {
                            console.log(e)
                        } finally {
                            this.loading = false
                        }
                    } else {
                        try {
                            this.loading = true
                            const { data } = await this.$http.post('/tasks/sprint/create/', queryData)
                            if(data) {
                                this.$message.success("Спринт создан")
                                eventBus.$emit('update_filter_tasks.TaskSprintModel')
                                this.visible = false
                            }
                        } catch(e) {
                            console.log(e)
                        } finally {
                            this.loading = false
                        }
                    }
                } else {
                    return false;
                }
            })
        },
        afterVisibleChange(vis) {
            if(!vis) {
                this.edit = false
                this.form = {
                    name: "",
                    target: "",
                    time_interval: null,
                    expected_result: [],
                    date_1: null,
                    date_2: null
                }
            }
        },
        async getSprint(sprint) {
            try {
                this.sprintLoading = true
                const { data } = await this.$http.get(`/tasks/sprint/${sprint.id}/`)
                if(data) {
                    this.form = {
                        ...data,
                        date_1: null,
                        date_2: null
                    }
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.sprintLoading = false
            }
        }
    },
    mounted() {
        eventBus.$on('add_sprint', () => {
            this.visible = true
        })
        eventBus.$on('edit_sprint', sprint => {
            this.edit = true
            this.visible = true
            this.getSprint(sprint)
        })
    },
    beforeDestroy() {
        eventBus.$off('add_sprint')
        eventBus.$off('edit_sprint')
    }
}
</script>