<template>
    <div>
        <Group :buttonSize="buttonSize" />
        <CreateGroup />

        <Project :buttonSize="buttonSize" />
        <CreateProject />
        <DrawerCreate />
        <CreateProjectTemplate />

    </div>
</template>

<script>
import storeGroupsAndProjects from "./store/index"
import Group from './Groups/Page'
import Project from './Projects/Page'
import CreateGroup from './Groups/CreateGroup'
import CreateProject from './Projects/CreateProject'
import CreateProjectTemplate from './Projects/CreateProjectTemplate'
import DrawerCreate from "./Projects/ProjectCreate.vue"

export default {
    name: "GroupsAndProjectsInit",
    components: {
        Group,
        Project,
        DrawerCreate,
        CreateGroup,
        CreateProject,
        CreateProjectTemplate
    },
    data() {
        return {
            buttonSize: 'large'
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    created() {
        if(!this.$store.hasModule('workgroups')) {
            this.$store.registerModule("workgroups", storeGroupsAndProjects)
            this.$store.state.connectedModules.push('workgroups')
        }
    }
}
</script>

<style lang="scss">
.pj_padding{
    padding: 20px 30px;
}
</style>