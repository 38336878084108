import { i18n } from '@/config/i18n-setup'

export default [
    {
        name: 'projects-list',
        path: '',
        isShow: false,
        isHidden: true,
        component: () => import(`../views/List.vue`),
        meta: {
            navWidget: "NavPage",
            get title() {
                return i18n.t('project.project_list')
            },
            icon: '',
            hideSidebar: true,
            isShow: false,
            hideMobile: false
        }
    },
    {
        name: 'projects-gant',
        path: 'gantt',
        isShow: false,
        isHidden: true,
        component: () => import(`../views/Gantt.vue`),
        meta: {
            navWidget: "NavPage",
            get title() {
                return i18n.t('project.project_gant')
            },
            icon: '',
            hideSidebar: true,
            isShow: false,
            hideMobile: false
        }
    }
]