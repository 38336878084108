<template>
    <component 
        :is="listComponent" 
        :tableType="tableType" 
        :page_name="page_name"
        :pageModel="pageModel"
        :pageConfig="pageConfig" />
</template>

<script>
import columns from '../mixins/columns.js'
export default {
    mixins: [
        columns
    ],
    props: {
        pageConfig: {
            type: Object,
            default: () => null
        },
        page_name: {
            type: String,
            default: 'page_list_projects.WorkgroupModel'
        },
        pageModel: {
            type: String,
            default: 'workgroups.WorkgroupModel'
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        listComponent() {
            if(this.isMobile) {
                return () => import('../components/ListInitMobile.vue')
            } else {
                return () => import('../components/ListInit.vue')
            }
        }
    },
    data() {
        return {
            listProject: true,
            tableType: 'projects'
        }
    }
}
</script>