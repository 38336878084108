export default {
    "gantt": {
        "toggleAside": "Скрыть/показать левую панель",
        "hours": "Часы",
        "days": "Дни",
        "weeks": "Недели",
        "months": "Месяцы",
        "quarters": "Кварталы",
        "years": "Годы",
        "today": "Сегодня",
        "project_start": "Начало проекта",
        "project_end": "Крайний срок",
        "error": "Произошла ошибка",
        "taskMinTime": "Минимальная длительность задачи — 10 минут.",
        "task": "Задача",
        "projectTask": "Проект/Задача",
        "startDate": "Начало",
        "endDate": "Конец",
        "duration": "Длительность",
        "status": "Статус",
        "fStartDate": "Дата начала",
        "fEndDate": "Крайний срок",
        "currentMonth": "Текущий месяц",
        "currentQuarters": "Текущий квартал",
        "currentYear": "Текущий год",
        "add_task": "Добавить задачу"
    }
}