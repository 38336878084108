export default {
    "task": {
        "auction": "Аукцион",
        "new": "Жаңа",
        "in_work": "Жұмыста",
        "on_pause": "Тоқтатылды",
        "on_check": "Тексеруде",
        "on_rework": "Қайта жасау",
        "completed": "Аяқталды",
        "to_work": "Орындауды бастау",
        "to_pause": "Тоқтыту",
        "to_check": "Тексеруге",
        "to_rework": "Қайта жасауды",
        "to_completed": "Аяқтау",
        "to_new": "Жаңа",
        "resume": "Жандандыру",
        "today": "Бүгін",
        "task": "Тапсырма",
        "interest": "Қызығушылық",
        "task2": "Тапсырма",
        "history": "Тарих",
        "created": "Жасалды",
        "dead_line": "Соңғы мерзім",
        "priority": "Приоритет",
        "contractor": "Тапсырыс беруші",
        "organization": "Ұйым",
        "potential_contractor": "Лид",
        "potential_contractor_client": "Клиент",
        "potential_contractor_region_name": "Бизнес аймағы",
        "potential_contractor_company_name": "Ұйым",
        "operator": "Жауапты",
        "visor": "Бақылаушы",
        "project": "Жоба",
        "workgroup": "Жұмыс тобы",
        "add_subtask": "Қосымша тапсырма қосу",
        "share_to_chat": "Бөлісу",
        "copy": "Көшіру",
        "edit": "Өзгерту",
        "remove": "Жою",
        "comments": "Пікірлер",
        "main_task": "Негізгі тапсырма:",
        "main_task_message": "Чат хабарламасынан негізделген тапсырма:",
        "main_task_comment": "Пікірден негізделген тапсырма:",
        "main_task_files": "Файлдан негізделген тапсырма:",
        "main_task_order": "Тапсырыс негізінде тапсырма:",
        "add": "Қосу",
        "no_description": "Сипаттама жоқ",
        "description": "Сипаттама",
        "task_deleted": "Тапсырма жойылды",
        "error": "Қате",
        "task_not_found": "Мұндай тапсырма жоқ немесе ол жойылған",
        "ultralow": "Өте төмен",
        "low": "Төмен",
        "middle": "Орташа",
        "tall": "Жоғары",
        "veryhigh": "Өте жоғары",
        "1": "Төмен",
        "2": "Орташа",
        "3": "Жоғары",
        "owner": "Құрастырушы",
        "field_require_all": "Міндетті өрістерді толтырыңыз",
        "field_require": "Толтыру міндетті",
        "field_min_require": "Кемінде {min} символ",
        "task_created": "Тапсырма жасалды",
        "task_updated": "Тапсырма жаңартылды",
        "edit_task": "Тапсырманы өзгерту",
        "add_task": "Тапсырма қосу",
        "add_milestone": "Маңызды кезеңді қосыңыз",
        "task_based": "Негізделген тапсырма",
        "task_name": "Тапсырманың атауы",
        "field_max_require": "Кемінде {max} символ",
        "task_desc": "Тапсырманың сипаттамасы",
        "task_duration": "Тапсырманың ұзақтығы",
        "select_performer": "Орындаушыны таңдау",
        "select_author": "Авторды таңдау",
        "observers": "Бақылаушылар",
        "select_observers": "Бақылаушыларды таңдау",
        "chat_message": "Чат хабарламалары",
        "comment": "Пікір",
        "file2": "Файл",
        "order": "Тапсырыс",
        "projects": "Жобалар",
        "additionally": "Қосымша",
        "additionally_fields": "Қосымша өрістер",
        "make_it_subtask": "Қосымша тапсырма жасау",
        "make_it_subissue": "Шағымға қатысушы",
        "subtask": "Қосымша тапсырмалар",
        "change": "Өзгерту",
        "select": "Таңдау",
        "files": "Файлдар",
        "save": "Сақтау",
        "create_and_open": "Сақтау және ашу",
        "create_and_create": "Сақтау және тағы біреуін жасау",
        "create_and_copy": "Сақтау және көшіру",
        "close": "Жабу",
        "select_task": "Тапсырманы таңдау",
        "no_time_limit": "Срок жоқ",
        "user_name": "Пайдаланушының аты",
        "select_user": "Пайдаланушыны таңдау",
        "project_name": "Жобаның атауы",
        "select_project": "Жобаны таңдау",
        "working_group_name": "Жұмыс тобының атауы",
        "select_working_group": "Жұмыс тобын таңдау",
        "select_date": "Күнді таңдау",
        "task-list-page": "Тапсырмалар",
        "task-calendar-page": "Күнтізбе",
        "task-kanban-page": "Канбан",
        "task-gantt-page": "Гант диаграммасы",
        "task-sprint-page": "Спринттер",
        "name": "Атауы",
        "status": "Статус",
        "actions": "Әрекеттер",
        "no_data": "Деректер жоқ",
        "load_more": "Тағы жүктеу",
        "create_fast_task": "Жедел тапсырма жасау",
        "tasks_not_deadline": "Соңғы мерзімі жоқ тапсырмалар",
        "week": "Апта",
        "month": "Ай",
        "quarter": "Квартал",
        "year": "Жыл",
        "day": "Күн",
        "action_disabled": "Әрекет тоқтатылды",
        "large_priority": "Жоғары приоритет",
        "very_large_priority": "Ең жоғары приоритет",
        "small_priority": "Төмен приоритет",
        "very_low_priority": "Өте төмен приоритет",
        "accounting": "Есеп",
        "participant_sprint": "Спринтке қатысушы",
        "user_not_owner": "Пайдаланушы тапсырманың иесі емес.",
        "author": "Автор",
        "date_start": "Жоспарланған шығу күні",
        "date_start_fact": "Нақты шығу күні",
        "date_start_plan": "Басталу күні",
        "date_change": "Соңғы мерзім|Басталу күні ата-аналық тапсырмаға сәйкес өзгертілді",
        "task_phone": "Телефон",
        "subject_matter": "Тақырып",
        "add_appeal": "Шағым қосу",
        "edit_appeal": "Шағымды өзгерту",
        "appeal_name": "Шағымның атауы",
        "open_task": "Тапсырманы ашу",
        "email": "Электрондық пошта",
        "organization_name": "Ұйымның атауы",
        "make_event": "Оқиға жасау",
        "excel_download": "Excel бағдарламасында жүктеп алыңыз",
        "loading": "Жүктелуде",
        "statistics_from": "бастап статистика",
        "file_is_generated": "Файл жасалады",
        "loading_error": "Жүктеу қатесі",
        "contractordrawer": {
            "choose": "Таңдау",
            "drawerTitle": "Клиентті таңдау",
            "searchPlaceholder": "Клиент аты"
        },
        "change_person": "Жауапты адамды ауыстырыңыз",
        "task_stat": "Тапсырма статистикасы",
        "stat_not_found": "Аналитика қолжетімді емес",
        "general_estimate": "Жалпы бағалау",
        "total_sum": "Жалпы сома:",
        "difficulty_rating": "Күрделілік рейтингі",
        "overall_rating": "Жалпы рейтинг:",
        "draft": "Жоба",
        "source_appeal": "Апелляцияның көзі",
        "upload_file": "Файлды жүктеп салу",
        "specify_address": "Мекенжайды көрсетіңіз",
        "specify_address_2": "Мекенжайларды көрсетіңіз",
        "delivery_from": "Бастап жеткізу",
        "task_from": "Тапсырма",
        "small_name": "Қысқа атау",
        "latitude": "Ендік",
        "longitude": "Дол",
        "address": "Address",
        "add_address": "Мекенжай қосыңыз",
        "clear": "Таза",
        "point_delete": "Нүктені шынымен жойғыңыз келе ме?",
        "yes": "Иә",
        "no": "Жоқ",
        "address_deleted": "Мекенжай жойылды",
        "form_field_required": "Пішіннің барлық өрістерін толтырыңыз!",
        "handler": {
            "confirmTakeTask": "Сіз шынымен тапсырманы алғыңыз келе ме?",
            "cancel": "Бас тарту",
            "ok": "Алу",
            "success": "Тапсырма сіздікі",
            "error": "Қате орын алды"
        },
        "file_share_error": "Файлды бөлісу мүмкін болмады",
        "mobile_sort": {
            "searchPlaceholder": "Іздеу",
            "descendingOrder": "Нөмірдің кемуі бойынша",
            "ascendingOrder": "Нөмірдің артуы бойынша",
            "byOperator": "Жауаптыға қарай",
            "newItems": "Жаңа заттар"
        },
        "task_empty": "Тапсырмалар жоқ",
        "took_on_task": "Бұл тапсырманы біреу алып қойған",
        "contact_user": "Байланыс жасайтын тұлға",
        "not_widget": "Бұл виджет жоқ",
        "no_data_2": "Жазбалар қолжетімді емес",
        "work_desc": "Жұмыс сипаттамасы",
        "work_type": "Жұмыс түрі",
        "date": "Күн",
        "count": "Саны",
        "unit_measurement": "Өлшем бірлігі",
        "warning": "Ескерту",
        "item_delete_message": "Бұл жазбаны шынымен жойғыңыз келе ме?",
        "item_removed": "Пост жойылды",
        "item_updated": "Пост жаңартылды",
        "item_added": "Жазба қосылды",
        "all": "Барлығы",
        "delivery": {
            "allPoints": "Барлығы",
            "address": "Мекенжай",
            "order": "Тапсырыс",
            "client": "Тапсырыс беруші",
            "phone": "Телефон",
            "email": "Электрондық пошта",
            "showOnMap": "Картада көрсету",
            "routeOnMap": "Маршрут картасы",
            "editRoute": "Маршрутты өзгерту",
            "save": "Сақтау",
            "paid_suppliers": "Жабдықтаушыларға төленеді",
            "delivery_date_and_time": "Жеткізу күні мен уақыты",
            "fact_delivery_date": "Нақты жеткізу күні",
            "pay": "Төлеу",
            "updated": "жаңартылды",
            "warehouseLoading": "Жүктеу қоймасы",
            "deliveryPoint": "Жеткізу нүктесі",
            "product_succes_delivery": "Өнім сәтті жөнелтілді"
        },
        "delete_message": "Жойғыңыз келетініне сенімдісіз бе?",
        "problem_task": "Проблемалық тапсырма",
        "add_to_sprint": "Спринтке қосыңыз",
        "delete_to_sprint": "Спринттен алып тастау",
        "task3": "тапсырмалар",
        "subtask_count": "Ішкі тапсырмалар саны",
        "comment_count": "Пікірлер саны",
        "files_count": "Қосылған файлдар саны",
        "card_desc": "Бұл карточка сипаттамасы"
    }
}