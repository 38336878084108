<template>
    <span > 
        <span @click="openTask" class="name blue_color">
            {{ record.name}}            
        </span>
        <template v-if="isMilestone">
            <a-tooltip :title="$t('Milestone')">
                <i class="ml-2 fi fi-rr-flag-alt"></i>
            </a-tooltip>
        </template>
    </span>
</template>

<script>
export default {
    props: {
        record: {
            type: Object,
            required: true
        },
        template: {
            type: String,
            default: ''
        }
    },
    components: {},
    computed: {
        isMilestone() {
            return this.record.task_type === 'milestone'
        },
    },
    data() {
        return {
            edit: false,
            loading: false,
            form: {
                name: "",
                description: "",
                duration: 0,
                taskType: ''
            },
            rules: {
                name: [
                    { required: true, message: this.$t('field_required'), trigger: "blur" },
                ],
                duration: [
                    { required: true, message: this.$t('field_required'), trigger: "blur" },
                ],
            }
        };
    },
    methods: {
        async openTask() {
            this.$store.commit('task/SET_PAGE_NAME', {
                pageName: this.pageName
            })

            if(this.main) {
                let query = Object.assign({}, this.$route.query)
                if(query.task && Number(query.task) !== this.record.id || !query.task) {
                    query.task = this.record.id
                    this.$router.push({query})
                    // this.reloadTask(this.record)
                }
            } else {
                let query = Object.assign({}, this.$route.query)
                if(!query.task) {
                    query.task = this.record.id
                } else {
                    delete query.task
                }
                await this.$router.push({query})

                // this.reloadTask(this.record)
            }
        }

    },
};
</script>

<style lang="scss" scoped>

.name {
    // word-break: break-all;
    cursor: pointer;
    &:hover{
        color: var(--primaryColor);
    }
}
</style>