<template>
    <component 
        :is="wrapperModule" 
        :pageTitle="pageTitle">
        <div v-if="inject" class="flex mb-4">
            <a-button 
                v-if="showCreateButton"
                icon="plus" 
                size="large" 
                type="primary"
                class="mr-2"
                @click="addSprint()">
                Создать спринт
            </a-button>
            <PageFilter 
                :model="model"
                :key="pageName"
                size="large"
                :page_name="pageName"/>
        </div>
        <template v-if="!inject" v-slot:h_left>
            <PageFilter 
                :model="model"
                :key="pageName"
                size="large"
                :page_name="pageName"/>
        </template>
        <template v-if="!inject" v-slot:h_right>
            <a-button 
                v-if="showCreateButton"
                icon="plus" 
                size="large" 
                type="primary"
                @click="addSprint()">
                Создать спринт
            </a-button>
        </template>
        <a-spin 
            :spinning="listLoading" 
            class="w-full">
            <SprintCard 
                v-for="sprint in sprintList" 
                :key="sprint.id" 
                :sprint="sprint" />
        </a-spin>
        <div class="flex justify-end pt-1">
            <a-pagination
                :current="page"
                :show-size-changer="pageSizeOptions.length > 1"
                :page-size.sync="pageSize"
                :defaultPageSize="Number(pageSize)"
                :pageSizeOptions="pageSizeOptions"
                :total="count"
                hideOnSinglePage
                show-less-items
                @showSizeChange="sizeSwicth"
                @change="changePage">
                <template slot="buildOptionText" slot-scope="props">
                    {{ props.value }}
                </template>
            </a-pagination>
        </div>
    </component>
</template>

<script>
import eventBus from '@/utils/eventBus'
import PageFilter from '@/components/PageFilter'
import ModuleWrapper from '@/components/ModuleWrapper/index.vue'
import SprintCard from './SprintCard.vue'
export default {
    name: "SprintList",
    components: { 
        PageFilter,
        ModuleWrapper,
        SprintCard
    },
    props: {
        tableType: {
            type: String,
            default: 'sprints'
        },
        filters: {
            type: Object,
            default: null
        },
        pageName: {
            type: String,
            default: "sprint_list"
        },
        showCreateButton: {
            type: Boolean,
            default: true
        },
        model: {
            type: String,
            default: 'tasks.TaskSprintModel'
        },
        showPageTitle: {
            type: Boolean,
            default: false
        },
        inject: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            sprintList: [],
            count: 0,
            listLoading: false,
            page: 1,
            pageSize: 15,
            pageSizeOptions: ['15', '30', '50']
        }
    },
    computed: {
        pageTitle() {
            return this.$route?.meta?.title || ''
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        wrapperModule() {
            if(this.inject)
                return 'div'
            else
                return () => import('@/components/ModuleWrapper/index.vue')
        }
    },
    created(){
        this.getSprints()
    },
    methods: {
        addSprint() {
            eventBus.$emit('add_sprint')
        },
        sizeSwicth(current, pageSize) {
            this.page = 1
            this.pageSize = Number(pageSize)
            this.getSprints()
        },
        changePage(page) {
            this.page = page
            this.getSprints()
        },
        async getSprints(){
            try{
                this.listLoading = true
                const params = { 
                    page: this.page,
                    page_name: this.pageName,
                    filters: this.filters,
                    page_size: this.pageSize
                }
                const { data } = await this.$http(`tasks/sprint/list/`, { params })
                if(data) {
                    this.sprintList = data.results
                    this.count = data.count
                }
            }
            catch(e) {
                console.log(e)
            }
            finally{
                this.listLoading = false
            }
        }
    },
    mounted() {
        eventBus.$on('sprint_update_table', sprint => {
            const idx = this.listSprints.findIndex(el=> el.id === sprint.id)
            if(idx !== -1) {
                this.$set(this.listSprints, idx, sprint)
            }
        })
        eventBus.$on(`update_filter_tasks.TaskSprintModel`, () => {
            this.page = 1
            this.getSprints()
        })
    },
    beforeDestroy(){
        eventBus.$off(`sprint_update_table`)
        eventBus.$off(`update_filter_tasks.TaskSprintModel`)
    }
}
</script>