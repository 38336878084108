<template>
    <div class="gant_cont" :class="!inject && 'gant_page'">
        <div class="toolbar flex items-center justify-between pb-2">
            <div v-if="inject" class="flex items-center">
                <a-button 
                    type="primary" 
                    icon="plus" 
                    class="mr-2"
                    size="large" 
                    @click="addTaskDrawer()">
                    {{ $t('gantt.add_task') }}
                </a-button>
                <PageFilter
                    :model="pageModel"
                    :key="page_name"
                    size="large"
                    :page_name="page_name" />
            </div>
            <div v-else>
                <a-range-picker
                    v-model="dateRange"
                    allowClear
                    :ranges="{
                        [$t('gantt.today')]: [$moment(), $moment()], 
                        [$t('gantt.currentMonth')]: [$moment().startOf('month'), $moment().endOf('month')],
                        [$t('gantt.currentQuarters')]: [$moment().startOf('quarter'), $moment().endOf('quarter')],
                        [$t('gantt.currentYear')]: [$moment().startOf('year'), $moment().endOf('year')]
                    }"
                    :locale="locale"
                    format="DD-MM-YYYY"
                    @change="onDateChange">
                    <template slot="suffixIcon">
                        <i class="fi fi-rr-calendar blue_color" />
                    </template>
                </a-range-picker>
            </div>
            <div class="flex items-center">
                <div class="flex items-center">
                    <a-slider 
                        v-model="zoomLevel"
                        :marks="marks" 
                        class="zoom_steps"
                        style="min-width: 120px;"
                        :min="0"
                        :max="4"
                        :tooltipVisible="false"
                        :step="null"
                        @afterChange="afterChange" />
                    <span class="ml-4" style="min-width: 70px;color:#000;">{{ zoomLevelName }}</span>
                </div>
                <div class="flex items-center">
                    <a-button 
                        type="ui" 
                        flaticon
                        :disabled="leftActive"
                        class="mr-1"
                        shape="circle"
                        icon="fi-rr-arrow-small-left"
                        @mouseenter="scrollLeft"
                        @mouseleave="clear"
                        @click="gantSetScroll('left')" />
                    <a-button 
                        type="ui" 
                        flaticon
                        :disabled="rightActive"
                        shape="circle"
                        icon="fi-rr-arrow-small-right"
                        @mouseenter="scrollRight"
                        @mouseleave="clear"
                        @click="gantSetScroll('right')" />
                </div>
                <a-button 
                    type="ui" 
                    flaticon
                    class="ml-1"
                    shape="circle"
                    v-tippy="{ inertia : true, duration : '[600,300]'}"
                    :content="$t('gantt.toggleAside')"
                    icon="fi-rr-sidebar"
                    @click="toggleGrid()" />
            </div>
        </div>
        <a-spin 
            class="gant_spin" 
            :spinning="loading">
            <div 
                class="gant_wrapper"
                ref="gant" 
                :class="`zoom_level_${zoomLevel}`"
                style="width: 100%;" />
        </a-spin>
        <div class="flex justify-end pt-1">
            <a-pagination 
                :value="page" 
                size="small"
                :total="count" 
                :defaultPageSize="page_size"
                show-less-items
                @change="pageChange" />
        </div>
    </div>
</template>

<script>
import { Gantt } from "@dhx/trial-gantt"
import "@dhx/trial-gantt/codebase/dhtmlxgantt.css"
import props from './props.js'
import filters from './filters.js'
import { durationFormat, marks, injectData } from './utils.js'
import eventBus from "@/utils/eventBus"
import { mapState } from 'vuex'
import PageFilter from '@/components/PageFilter'
let stepTimer;
let widthTimer;
let dragTimer;
export default {
    mixins: [props, filters],
    components: {
        PageFilter
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        zoomLevelName() {
            switch (this.zoomLevel) {
            /*case 0:
                return this.$t('gantt.hours')*/
            case 0:
                return this.$t('gantt.days')
            case 1:
                return this.$t('gantt.weeks')
            case 2:
                return this.$t('gantt.months')
            case 3:
                return this.$t('gantt.quarters')
            case 4:
                return this.$t('gantt.years')
            default:
                return this.$t('gantt.hours')
            }
        },
        isInject() {
            return this.inject ? '_INJECT' : ''
        }
    },
    data() {
        return {
            isDragging: false,
            saveScroll: null,
            page: 1,
            count: 0,
            leftActive: false,
            rightActive: false,
            gantList: {
                data: []
            },
            gantRef: null,
            gantt: null,
            loading: false,
            zoomLevel: 0,
            isGridVisible: true,
            gridWidth: 500,
            page_size: 20,
            scrollTimer: null,
            task_type: 'task,stage,milestone',
            marks
        }
    },
    methods: {
        statusUpdate({task, status}) {
            try {
                const gantTask = this.gantt.getTask(task.id)
                if(gantTask) {
                    const taskUpdate = {
                        status
                    }
                    Object.assign(gantTask, taskUpdate)
                    this.gantt.updateTask(gantTask.id)
                }
            } catch(e) {
                console.log(e)
            }
        },
        addTask(data) {
            try {
                if(!this.onlyTask && data.project) {
                    const task = this.gantt.getTask(data.project.id)
                    if(task) {
                        const projectUpdate = {
                            $has_child: true
                        }
                        Object.assign(task, projectUpdate)
                        this.gantt.updateTask(task.id)
                    }
                }
                if(data.parent) {
                    const task = this.gantt.getTask(data.parent.id)
                    if(task) {
                        const taskUpdate = {
                            $has_child: true
                        }
                        Object.assign(task, taskUpdate)
                        this.gantt.updateTask(task.id)
                    }
                }
                if(data.date_start_plan && data.dead_line) {
                    let taskAdd = false
                    const taskData = {
                        id: data.id,
                        text: data.counter ? `${data.name} - ${data.counter}` : data.name,
                        status: data.status,
                        operator: data.operator,
                        owner: data.owner,
                        project: data.project,
                        duration: data.duration,
                        start_date: this.$moment(data.date_start_plan).toDate(),
                        end_date: this.$moment(data.dead_line).toDate(),
                        type: data.task_type || 'task'
                    }
                    if(!this.inject) {
                        if(data.project)
                            taskData.parent = data.project.id
                    }
                    if(data.parent)
                        taskData.parent = data.parent.id
                    if(taskData.parent) {
                        const parentTask = this.gantt.getTask(taskData.parent)
                        if(parentTask?.$open || parentTask.loaded) {
                            taskAdd = true
                        }
                    } else 
                        taskAdd = true
                    if(taskAdd) {
                        this.gantt.clearAll()
                        this.gantList.data.push(taskData)
                        this.gantList.data.forEach(item => {
                            this.gantt.addTask(item)
                        })
                        this.calculateGanttRange()
                        this.markersInit()
                    }
                }
            } catch(e) {
                console.log(e)
            }
        },
        updateTask(data) {
            try {
                const task = this.gantt.getTask(data.id)
                if(task) {
                    const taskUpdate = {
                        text: `${data.name} - ${data.counter}`,
                        status: data.status,
                        operator: data.operator,
                        owner: data.owner,
                        duration: data.duration,
                        start_date: this.$moment(data.date_start_plan).toDate(),
                        end_date: this.$moment(data.dead_line).toDate(),
                    }
                    if(this.inject) {
                        taskUpdate.parent = data.parent?.id ? data.parent.id : 0
                        if(!data.project || data.project?.id !== this.related_object)
                            this.deleteTask(task)
                    }
                    const checkTask = this.gantt.getTask(data.id)
                    if(checkTask) {
                        Object.assign(task, taskUpdate)
                        this.gantt.updateTask(task.id)
                    }
                }
            } catch(e) {
                console.log(e, 'UPDATE_TASK_KANBAN')
            }
        },
        deleteTask(task) {
            try {
                const gantTask = this.gantt.getTask(task.id)
                if(gantTask) {
                    this.gantt.deleteTask(gantTask.id)
                    this.$nextTick(() => {
                        const index = this.gantList.data.findIndex(f => f.id === gantTask.id)
                        if(index !== -1)
                            this.gantList.data.splice(index, 1)
                        if(gantTask.parent) {
                            const parentTask = this.gantt.getTask(gantTask.parent)
                            if(parentTask) {
                                const childTask = this.gantList.data.filter(f => f.parent === parentTask.id)
                                if(!childTask.length) {
                                    const taskUpdate = {
                                        $has_child: false
                                    }
                                    Object.assign(parentTask, taskUpdate)
                                    this.gantt.updateTask(parentTask.id)
                                }
                            }
                        }
                    })
                }
            } catch(e) {
                console.log(e)
            }
        },
        markersInit() {
            if(this.showTodayMarker) {
                this.gantt.addMarker({
                    start_date: new Date(),
                    text: this.$t('gantt.today'),
                    css: "today-marker"
                })
            }
            if(this.useForceDatesMarks && this.forceStartDate && this.forceEndDate) {
                this.gantt.addMarker({
                    start_date: this.$moment(this.forceStartDate).toDate(),
                    text: this.$t('gantt.project_start'),
                    css: "start-marker deadline-marker"
                })
                this.gantt.addMarker({
                    start_date: this.$moment(this.forceEndDate).toDate(),
                    text: this.$t('gantt.project_end'),
                    css: "end-marker deadline-marker"
                })
            }
        },
        async changeTask(task) {
            try {
                const taskData = {
                    date_start_plan: this.$moment(task.start_date).format(),
                    dead_line: this.$moment(task.end_date).format()
                }
                const { data } = await this.$http.put(`/tasks/task/${task.id}/update/`, taskData)
                if(data) {
                    const uTask = this.gantt.getTask(data.id)
                    if(uTask) {
                        const taskUpdate = {
                            duration: data.duration
                        }
                        Object.assign(uTask, taskUpdate)
                        this.gantt.updateTask(uTask.id)
                    }
                }
            } catch(error) {
                const taskData = this.gantt.getTask(task.id)
                if(taskData) {
                    const taskUpdate = {
                        start_date: taskData.original_start_date,
                        end_date: taskData.original_end_date,
                        duration: taskData.original_duration
                    }
                    Object.assign(taskData, taskUpdate)
                    this.gantt.updateTask(taskData.id)
                }
                this.handleError(error)
            }
        },
        handleError(error) {
            let errorMessage = this.$t('gantt.error')
            if (error && error) {
                const errorData = error
                if (typeof errorData === 'string')
                    errorMessage = errorData
                if (Array.isArray(errorData))
                    errorMessage = errorData.join(', ')
            }
            console.log(error, 'error')
            this.$message.error(errorMessage)
        },
        gantSetScroll(type) {
            const scrollState = this.gantt.getScrollState()
            if(type === 'left') {
                this.gantt.scrollTo(0, scrollState.y)
            }
            if(type === 'right') {
                const ganttContainer = this.gantt.$task
                this.gantt.scrollTo(ganttContainer.scrollWidth, scrollState.y)
            }
        },
        scrollLeft() {
            this.scrollTimer = setInterval(() => {
                const scrollState = this.gantt.getScrollState()
                const scrollSpeed = this.zoomLevel === 0 || this.zoomLevel === 1 ? 20 : 10
                this.gantt.scrollTo(scrollState.x-scrollSpeed, scrollState.y)
            }, 10)
        },
        scrollRight() {
            this.scrollTimer = setInterval(() => {
                const scrollState = this.gantt.getScrollState()
                const scrollSpeed = this.zoomLevel === 0 || this.zoomLevel === 1 ? 20 : 10
                this.gantt.scrollTo(scrollState.x+scrollSpeed, scrollState.y)
            }, 10)
        },
        clear() {
            clearInterval(this.scrollTimer)
        },
        addTaskDrawer() {
            this.$store.commit('task/SET_TASK_DRAWER_ZINDEX', 1010)
            this.$store.dispatch('task/sidebarOpen', {
                ...this.formParams,
                task_type: 'task'
            })
        },
        calculateGanttRange() {
            if (this.gantList.data?.length) {
                const minDate = this.$moment.min(
                    this.gantList.data.map(task => this.$moment(task.start_date))
                )
                const maxDate = this.$moment.max(
                    this.gantList.data.map(task => this.$moment(task.end_date))
                )
                this.gantt.config.start_date = minDate.add(-1, 'month').toDate()
                this.gantt.config.end_date = maxDate.add(2, 'month').toDate()
            }
            this.gantt.render()
        },
        pageChange(page) {
            this.page = page
            this.gantReload()
        },
        gantReload() {
            this.gantList = {
                data: []
            }
            this.gantt.clearAll()
            if(this.useProjects)
                this.getProjects()
            else
                this.getTasks()
        },
        setCurrentScroll() {
            if(this.saveScroll) {
                this.gantt.scrollTo(this.saveScroll.x, this.saveScroll.y)
                this.saveScroll = null
            }
        },
        async getChildTask(parent) {
            try {
                this.saveScroll = this.gantt.getScrollState()
                this.loading = true
                const params = {
                    page_name: this.page_name,
                    page: 1,
                    page_size: 'all',
                    task_type: this.task_type
                }
                if(parent.type === 'project') {
                    params.parent = 'null'
                    params.filters = JSON.stringify({project: parent.id})
                } else
                    params.parent = parent.id
                if(this.related_object)
                    params.filters = JSON.stringify({project: this.related_object})
                const { data } = await this.$http.get('/tasks/tasks_chart_gantt_v2/list/', { params })
                if(data) {
                    this.gantList.data = this.gantList.data.concat(data.results.map(item => {
                        const taskItem = {
                            ...item,
                            ...injectData,
                            $has_child: item.has_child,
                            parent: parent.id,
                            text: item.counter ? `${item.text} - ${item.counter}` : item.text,
                            loaded: false,
                            duration: item.type === 'milestone' ? 0 : item.duration
                        }
                        if(item.type === 'milestone') {
                            delete taskItem.end_date
                            taskItem.$no_end = true
                        }
                        return taskItem
                    }))
                    this.gantt.clearAll()
                    this.gantList.data.forEach(item => {
                        this.gantt.addTask(item)
                    })
                    this.calculateGanttRange()
                    this.setCurrentScroll()
                    this.markersInit()
                }
                this.setInitStateScrollBtn()
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        async getTasks() {
            try {
                this.loading = true
                const params = {
                    page_name: this.page_name,
                    page: this.page,
                    page_size: this.page_size,
                    parent: 'all',
                    task_type: this.task_type
                }
                if(this.related_object)
                    params.filters = JSON.stringify({project: this.related_object})
                const { data } = await this.$http.get('/tasks/tasks_chart_gantt_v2/list/', { params })
                if(data) {
                    this.count = data.count
                    this.gantList.data = data.results.map(item => {
                        const taskItem = {
                            ...item,
                            ...injectData,
                            $has_child: item.has_child,
                            text: item.counter ? `${item.text} - ${item.counter}` : item.text,
                            loaded: false,
                            duration: item.type === 'milestone' ? 0 : item.duration
                        }
                        if(item.type === 'milestone') {
                            taskItem.$no_end = true
                            delete taskItem.end_date
                        }
                        return taskItem
                    })
                    //this.gantt.parse({...this.gantList})
                    this.gantList.data.forEach(item => {
                        this.gantt.addTask(item)
                    })
                    this.calculateGanttRange()
                    this.setGanttScale(this.zoomLevel)
                } else {
                    this.count = 0
                    this.gantList.data = []
                }
                this.setInitStateScrollBtn()
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        async getProjects() {
            try {
                this.loading = true
                const params = {
                    page_name: this.page_name,
                    is_project: 1,
                    page: this.page,
                    page_size: this.page_size,
                    task_type: this.task_type
                }
                const { data } = await this.$http.get('/work_groups/workgroups/gantt_chart/', { params })
                if(data) {
                    this.count = data.count
                    this.gantList.data = data.results.map(item => {
                        const taskItem = {
                            ...item,
                            ...injectData,
                            $has_child: item.has_child,
                            loaded: false,
                            duration: item.type === 'milestone' ? 0 : item.duration
                        }
                        if(item.type === 'milestone') {
                            taskItem.$no_end = true
                            delete taskItem.end_date
                        }
                        return taskItem
                    })
                    //this.gantt.parse({...this.gantList})
                    this.gantList.data.forEach(item => {
                        this.gantt.addTask(item)
                    })
                    this.calculateGanttRange()
                    this.setGanttScale(this.zoomLevel)
                } else {
                    this.count = 0
                    this.gantList.data = []
                }
                this.setInitStateScrollBtn()
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        saveLocalStorage() {
            localStorage.setItem('gant_conf', JSON.stringify({
                zoomLevel: this.zoomLevel,
                isGridVisible: this.isGridVisible,
                gridWidth: this.gridWidth
            }))
        },
        toggleGrid(init = false) {
            if(!init)
                this.isGridVisible = !this.isGridVisible

            if (this.isGridVisible)
                this.gantt.config.grid_width = 500
            else
                this.gantt.config.grid_width = 0

            this.gantt.render()
            this.saveLocalStorage()
        },
        afterChange(value) {
            clearTimeout(stepTimer)
            stepTimer = setTimeout(() => {
                this.setGanttScale(value)
            }, 500)
        },
        setGanttScale(level) {
            switch (level) {
            /*case 0: // Часы
                this.gantt.config.scale_unit = "day"
                this.gantt.config.date_scale = "%d %M"
                this.gantt.config.subscales = [
                    { unit: "hour", step: 1, date: "%H:%i" }
                ]
                break;*/
            case 0: // Дни
                this.gantt.config.scale_unit = "month"
                this.gantt.config.date_scale = "%F %Y"
                this.gantt.config.subscales = [
                    { unit: "day", step: 1, date: "%d" }
                ]
                break;
            case 1: // Недели
                this.gantt.config.scale_unit = "month"
                this.gantt.config.date_scale = "%F %Y"
                this.gantt.config.subscales = [
                    {
                        unit: "week", 
                        step: 1, 
                        date: (date) => {
                            const start = this.gantt.date.date_to_str("%d")(date)
                            const end = this.gantt.date.date_to_str("%d")(
                                this.gantt.date.add(date, 6, "day")
                            );
                            return `${start}-${end}`
                        }
                    }
                ];
                break;
            case 2: // Месяцы
                this.gantt.config.scale_unit = "year"
                this.gantt.config.date_scale = "%Y"
                this.gantt.config.subscales = [
                    { unit: "month", step: 1, date: "%M" }
                ]
                break;
            case 3: // Кварталы
                this.gantt.date.quarter_str = function (date) {
                    const quarter = Math.floor(date.getMonth() / 3) + 1
                    return `${quarter} квартал`
                }
                this.gantt.config.scale_unit = "year"
                this.gantt.config.date_scale = "%Y"
                this.gantt.config.subscales = [
                    { unit: "quarter", step: 1, date: this.gantt.date.quarter_str }
                ]
                this.gantt.render()
                break;
            case 4: // Годы
                this.gantt.config.scale_unit = "year"
                this.gantt.config.date_scale = "%Y"
                this.gantt.config.subscales = []
                break;
            }
            this.gantt.render()
            this.saveLocalStorage()
            this.setInitStateScrollBtn()
        },
        setInitStateScrollBtn() {
            this.$nextTick(() => {
                const scrollState = this.gantt.getScrollState()
                if(scrollState.x === 0)
                    this.leftActive = true
                const ganttContainer = this.gantt.$task
                const ganttWidth = ganttContainer.offsetWidth
                const totalWidth = ganttContainer.scrollWidth 
                if (scrollState.x + ganttWidth >= totalWidth)
                    this.rightActive = true
                else
                    this.rightActive = false
            })
        },
        // Получение видимого диапазона
        fetchVisibleData() {
            const state = this.gantt.getState()
            const visibleStartDate = state.min_date
            const visibleEndDate = state.max_date
            console.log(visibleStartDate, visibleEndDate)
        },
        clickGridButton(taskId, action) {
            const gantgTask = this.gantt.getTask(taskId)
            this.$store.commit('task/SET_TASK_DRAWER_ZINDEX', 1010)
            if(action === 'add') {
                if(gantgTask) {
                    if(gantgTask.type === 'project') {
                        this.$store.dispatch('task/sidebarOpen', {
                            project: {
                                name: gantgTask.text, 
                                id: gantgTask.id,
                                workgroup_logo: null,
                                date_start_plan: this.$moment(gantgTask.start_date).format(),
                                dead_line: this.$moment(gantgTask.end_date).format(),
                                workgroup_logo: gantgTask.workgroup_logo?.is_image ? gantgTask.workgroup_logo : null
                            },
                            task_type: 'task'
                        })
                    } else {
                        const taskObject = {
                            ...gantgTask,
                            name: gantgTask.text,
                            dead_line: this.$moment(gantgTask.end_date).format(),
                            date_start_plan: this.$moment(gantgTask.start_date).format(),
                            task_type: 'task'
                        }
                        eventBus.$emit('ADD_WATCH', {type: 'subtask', data: taskObject})
                    }
                }
            }
        }
    },
    mounted() {
        this.gantRef = this.$refs.gant
        let clickTimeout = null

        let gantt = Gantt.getGanttInstance()
        gantt.i18n.setLocale("ru")
        gantt.plugins({ 
            marker: true
        })

        const config = JSON.parse(localStorage.getItem('gant_conf'))
        if(config) {
            this.zoomLevel = config.zoomLevel
            this.isGridVisible = config.isGridVisible
        }
        gantt.config.show_progress = this.useProgress
        gantt.config.show_links = this.useLinks
        gantt.config.drag_move = this.useEdit
        gantt.config.drag_resize = this.useEdit
        gantt.config.drag_progress = this.useEdit
        gantt.config.drag_links = this.useEdit
        gantt.config.grid_resize = this.useGridResize
        gantt.config.scroll_size = this.scrollSize
        gantt.config.select_task = this.selectTask
        gantt.config.date_format = this.dateFormat
        gantt.config.xml_date = this.dateFormat
        gantt.config.date_grid = this.dateGrid
        gantt.config.task_date = this.taskDate
        gantt.config.time_picker = this.timePicker
        gantt.config.resize_rows = this.resizeRows
        gantt.config.show_date = true
        gantt.config.current_time = true
        gantt.config.scale_height = this.scaleHeight
        gantt.config.order_branch = false //this.orderBranch
        gantt.config.order_branch_free = this.orderBranch
        gantt.config.branch_loading = true
        gantt.config.sort = true
        gantt.config.duration_unit = this.durationUnit
        gantt.config.duration_step = this.durationStep
        gantt.config.autocalculate_duration = false
        gantt.config.autoset_dates = false
        gantt.config.fit_tasks = true
        gantt.assert = function () {}
        gantt.config.drag_mode.order = false
        gantt.config.show_tasks_outside_timescale = true
        gantt.config.smart_rendering = true
        //gantt.config.server_utc = true

        gantt.attachEvent("onTaskDblClick", id => {
            if(this.isDragging)
                this.isDragging = false
            const task = gantt.getTask(id)
            if(task) {
                const query = Object.assign({}, this.$route.query)
                if(task.type === 'project') {
                    query.viewProject = task.id
                    this.$router.push({query})
                } else {
                    if(query.task && Number(query.task) !== task.id || !query.task) {
                        query.task = task.id
                        this.$router.push({query})
                    }
                }
            }
            return true
        })

        gantt.attachEvent('onBeforeTaskDrag', (id, mode, e) => {
            if (mode === gantt.config.drag_mode.order) {
                return false
            }
            const task = gantt.getTask(id)
            if(task) {
                const taskUpdate = {
                    original_start_date: task.start_date,
                    original_end_date: task.end_date,
                    original_duration: task.duration
                }
                Object.assign(task, taskUpdate)
                gantt.updateTask(task.id)
            }
            if (task?.type === "project" || task?.type === "stage")
                return false
            if(task.operator.id !== this.user.id || task.owner.id !== this.user.id || task.project?.author?.id !== this.user.id)
                return false
            return !clickTimeout
        })
        gantt.attachEvent('onTaskDrag', (id, mode, task, original) => {
            this.isDragging = true

            if (mode === "resize") {
                const durationInMinutes = gantt.calculateDuration(task.start_date, task.end_date) * 60
                if (durationInMinutes < 10) {
                    task.start_date = original.start_date
                    task.end_date = gantt.addMinutes(original.start_date, 10)
                }
            }
            return true
        })

        gantt.addMinutes = (date, minutes) => {
            return new Date(date.getTime() + minutes * 60 * 1000)
        }

        gantt.attachEvent('onTaskDragEnd', () => {
            this.isDragging = false
        })

        gantt.attachEvent("onBeforeTaskChanged", function (id, mode, task) {
            if (mode === "resize") {
                const minDuration = 10 / 60 / 24;
                const newDuration = gantt.calculateDuration(task.start_date, task.end_date);

                if (newDuration < minDuration) {
                    gantt.message({ text: this.$t('gantt.taskMinTime'), type: "error" });
                    return false;
                }
            }
            return true;
        });

        gantt.attachEvent("onTaskOpened", id => {
            const task = gantt.getTask(id);
            if (task.$has_child && !task.loaded) {
                this.getChildTask(task)
                const index = this.gantList.data.findIndex(f => f.id === id)
                if(index !== -1)
                    this.$set(this.gantList.data[index], 'loaded', true)
            }
            return true
        })

        gantt.attachEvent("onBeforeTaskDisplay", function (id, task) {
            task.$calculate_duration = false
            task.$no_start = false
            task.$no_end = false
            return true
        });
        
        gantt.templates.scale_cell_class = (date) => {
            if (date.getDay() === 0 || date.getDay() === 6) {
                return "gantt-weekend"
            }
        }
        gantt.templates.timeline_cell_class = (item, date) => {
            if (date.getDay() === 0 || date.getDay() === 6) {
                return "gantt-weekend"
            }
        }
        gantt.templates.grid_header_class = function (columnName, column) {
            if (columnName === "add") {
                return "hide-add-button"
            }
            return ""
        }

        let columns = [
            {
                name:"text",
                label: this.useProjects ? this.$t('gantt.projectTask') : this.$t('gantt.task'),
                width:300,
                tree: true,
                template: obj => {
                    if(obj.type === 'project')
                        return `<div class="wght_b">${obj.text}</div>`
                    else {
                        if(obj.type === 'stage')
                            return `<div class="wght_b">${obj.text} - ${obj.counter}</div>`
                        return `${obj.text} - ${obj.counter}`
                    }
                }
            },
            {
                name:"start_date",
                label: this.$t('gantt.startDate'),
                align:"center",
                template: obj => {
                    return this.$moment(obj.start_date).format('DD.MM.YYYY')
                }
            },
            {
                name:"end_date",
                label: this.$t('gantt.endDate'),
                align:"center",
                template: obj => {
                    return this.$moment(obj.end_date).format('DD.MM.YYYY')
                }
            },
            {
                name:"duration",
                label: this.$t('gantt.duration'),
                align:"center",
                template: obj => {
                    if(obj.type === 'milestone')
                        return ''
                    return durationFormat(obj.duration)
                }
            },
            {
                name:"status",
                label: this.$t('gantt.status'),
                align:"center",
                template: obj => {
                    if(obj.status)
                        return `<span class="ant-tag ant-tag-${obj.status.color}">${obj.status.name}</span>`
                    return ''
                }
            }
        ]
        if(this.useEdit) {
            columns.push({
                name: "buttons",
                label: ``,
                align:"center",
                width: 40,
                template: () => {
                    return `
                        <i class="fi fi-rr-plus gantt_button_grid gantt_grid_add cursor-pointer" />
                    `
                }
            })
        }
        gantt.config.columns = columns

        gantt.templates.task_text = function (start, end, task) {
            return task.text
        }

        gantt.templates.task_class = (start, end, task) => {
            if (task.type === 'task')
                return 'gantt-task-task'
            if (task.type === 'stage')
                return 'gantt-task-stage'
            return ''
        }

        gantt.templates.task_row_class = (start, end, task) => {
            if (task.type === "project" || task.type === "stage")
                return "gantt-row-bold"
            return ""
        }

        gantt.attachEvent("onTaskClick", (id, e) => {
            const target = e.target

            if (
                target.classList.contains("gantt_grid_edit") ||
                target.classList.contains("gantt_grid_add") ||
                target.classList.contains("gantt_grid_delete")
            ) {
                if (e.target.classList.contains("gantt_grid_add")) {
                    this.clickGridButton(id, "add")
                }
                e.preventDefault()
                return false
            }

            if (target?.classList.contains('gantt_tree_icon'))
                return true
            if (this.isDragging)
                return false

            if (clickTimeout) {
                clearTimeout(clickTimeout)
                clickTimeout = null
                return false
            }

            clickTimeout = setTimeout(() => {
                clickTimeout = null
            }, 300)

            return true
        })

        gantt.attachEvent("onBeforeRowDragMove", function(id, parent, tindex){
            console.log(id, parent, tindex, 'onBeforeRowDragMove')
            return true
        })

        gantt.attachEvent("onGridResize", (old_width, new_width) => {
            clearTimeout(widthTimer)
            widthTimer = setTimeout(() => {
                this.gridWidth = new_width
                this.saveLocalStorage()
            }, 400)
        })

        gantt.attachEvent("onAfterTaskMove", function(id, parent, tindex){
            clearTimeout(dragTimer)
            dragTimer = setTimeout(() => {
                // console.log("Task order to:", gantt.getTask(id))
            }, 800)
            return false
        })

        gantt.attachEvent("onAfterTaskDrag", (id, mode, e) => {
            const task = gantt.getTask(id)
            if(task) {
                if(mode === 'move' || mode === 'resize')
                    this.changeTask(task)
            }
        })

        gantt.attachEvent("onGanttScroll", (left, top) => {
            if(left === 0)
                this.leftActive = true
            else
                this.leftActive = false

            const ganttContainer = this.gantt.$task
            const ganttWidth = ganttContainer.offsetWidth
            const totalWidth = ganttContainer.scrollWidth 
            if (left + ganttWidth >= totalWidth)
                this.rightActive = true
            else
                this.rightActive = false
        })

        /*
        gantt.templates.timeline_cell_class = (task, date) => {
            if(this.forceStartDate && this.forceEndDate) {
                const startDate = this.$moment(this.forceStartDate)
                const endDate = this.$moment(this.forceEndDate)
                const currentDate = this.$moment(date)
                if (currentDate.isBetween(startDate, endDate, "minute", "[]"))
                    return "highlighted-range"
                return ""
            }
        }*/

        gantt.attachEvent("onBeforeLightbox", () => false)
        gantt.init(this.$refs.gant)
        this.gantt = gantt
        this.toggleGrid(true)
        this.setGanttScale(this.zoomLevel)
        this.markersInit()

        if(this.useProjects)
            this.getProjects()
        else
            this.getTasks()

        eventBus.$on(`update_filter_${this.pageModel}_${this.page_name}`, () => {
            this.gantReload()
        })
        eventBus.$on('update_list_project', () => {
            this.gantReload()
        })
        eventBus.$on(`ADD_TASK_H${this.isInject}`, data => {
            this.addTask(data)
        })
        eventBus.$on(`UPDATE_TASK_H${this.isInject}`, data => {
            this.updateTask(data)
        })
        eventBus.$on(`STATUS_TASK_H${this.isInject}`, ({task, status}) => {
            this.statusUpdate({task, status})
        })
        eventBus.$on(`DELETE_TASK_H${this.isInject}`, task => {
            this.deleteTask(task)
        })
        eventBus.$on('project_deleted', id => {
            try {
                const task = this.gantt.getTask(id)
                if(task) {
                    this.gantt.deleteTask(task.id)
                    this.$nextTick(() => {
                        const index = this.gantList.data.findIndex(f => f.id === task.id)
                        if(index !== -1)
                            this.gantList.data.splice(index, 1)
                    })
                }
            } catch(e) {
                console.log(e)
            }
        })
    },
    beforeDestroy() {
        if (this.gantt) this.gantt.destructor()
        this.gantRef.innerHTML = ""
        this.gantRef = null
        this.gantt = null
        eventBus.$off(`update_filter_${this.pageModel}_${this.page_name}`)
        eventBus.$off('update_list_project')
        eventBus.$off(`UPDATE_TASK_H${this.isInject}`)
        eventBus.$off(`ADD_TASK_H${this.isInject}`)
        eventBus.$off(`STATUS_TASK_H${this.isInject}`)
        eventBus.$off(`DELETE_TASK_H${this.isInject}`)
        eventBus.$off('project_deleted')
    }
}
</script>

<style lang="scss" scoped>
.gant_cont{
    height: 100%;
    &.gant_page{
        padding: 20px;
    }
}
.gant_spin{
    height: calc(100% - 68px);
    &::v-deep{
        .ant-spin-container{
            height: 100%;
        }
    }
}
.zoom_steps{
    margin: 0px;
    &::v-deep{
        .ant-slider-handle{
            border-color: var(--blue);
            background: var(--blue);
        }
        .ant-slider-rail{
            background-color: #ccc;
        }
        .ant-slider-step{
            .ant-slider-dot{
                border-color: #ccc;
            }
        }
        .ant-slider-track{
            display: none;
        }
    }
}
.gant_wrapper{
    height: 100%;
    &::v-deep{
        .wght_b{
            font-weight: 600;
        }
        .gantt_task_row,
        .gantt_row{
            &.gantt_selected{
                background-color: rgba(228, 230, 234, 0.3);
            }
        }
        .gantt_row{
            &:hover{
                background-color: rgba(228, 230, 234, 0.6);
            }
        }
        .gantt_layout_root{
            border-radius: 8px;
        }
        .hide-add-button {
            display: none !important;
        }
        .gantt_grid_head_cell{
            background: #f8f8f8;
        }
        .gantt_task_scale{
            .gantt_scale_cell{
                background: #f8f8f8;
            }
        }
        .highlighted-range {
            background-color: #f8fafc;
        }
        .gantt_task_line{
            &.gantt-task-task{
                border-radius: 30px;
                background: var(--blue);
            }
            &.gantt-task-stage{
                background: #7f86ab;
            }
        }
        .gantt_tree_icon.gantt_open:before, .gantt_tree_icon.gantt_close:before{
            font-family: 'icomoon' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 14px!important;
            height: 100%;
            display: flex;
            align-items: center;
        }
        .gantt_tree_icon.gantt_close:before{
            content: "\ee58";
        }
        .gantt_tree_icon.gantt_open:before{
            content: "\ee5a";
        }
        .gantt_task_drag{
            height: 27px;
            width: 12px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            &::after{
                content: "";
                background: #fff;
                position: absolute;
                top: 6px;
                bottom: 5px;
                width: 3px;
                border-radius: 10px;
                opacity: 0.6;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
            &.task_left{
                left: 0px;
                &::after{
                    right: 2px;
                }
            }
            &.task_right{
                right: 0px;
                &::after{
                    left: 2px;
                }
            }
            &:hover{
                &::after{
                    opacity: 0.9;
                }
            }
        }
        .gantt-task-stage,
        .gantt_bar_project{
            .gantt_task_drag{
                display: none;
            }
            &.gantt_drag_move,
            &.gantt_selected{
                .gantt_task_content{
                    cursor: pointer;
                }
            }
        }
        .deadline-marker{
            background-color: #7e5ab8;
        }
        .gantt_marker{
            .gantt_marker_content{
                border-radius: 0 4px 4px 0;
            }
        }
        .deadline-marker{
            width: 0px;
            .gantt_marker_content{
                border-radius: 4px;
            }
        }
    }
    &.zoom_level_0{
        &::v-deep{
            .gantt-weekend {
                background-color: #f4f7fa;
            }
        }
    }
}
</style>