export default {
    tables: {},
    workgroupData: [],
    listGroups: [],
    groupNext: true,
    listProjects: [],
    loading: false,
    userDrawer: {
        results: [],
        next: true,
        count: 0,
        page: 0
    },
    groupTable: {},
    projectTable: {},
    tableColumns: {},
    templateTable: {}
}