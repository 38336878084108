export default {
    "project": {
        "search": "Іздеу",
        "private_group:": "Жеке",
        "work_groups": "Командалар",
        "work-groups": "Командалар",
        "count_task": "Барлық тапсырмалар",
        "about_me": "Біз туралы",
        "hide": "Жасыру",
        "status": "Мәртебе",
        "closed": "Жабық",
        "closed_group": "Жабық команда",
        "author_chat": "Авторға жазу",
        "group_info_hide": "Ақпарат жасырын",
        "group_info_hide_desc": "Толық ақпаратты көру үшін топқа қосылуыңыз қажет",
        "public": "Қоғамдық",
        "date_create": "Құрылған күні",
        "date_begin": "Басталу уақыты",
        "image_not_found": "Суреттер жоқ",
        "add_image": "Сурет қосу",
        "gallery": "Галерея",
        "remove_event": "Оқиғаны жою",
        "events": "Оқиғалар",
        "gant": "Гант диаграммасы",
        "social_links": "Әлеуметтік желілерге сілтемелер",
        "type": "Түрі",
        "edit": "Өңдеу",
        "news_not_found": "Жаңалықтар жоқ",
        "no_partisipants_group": "Сіз жабық команданың мүшесі емессіз",
        "invite_participants": "Қатысушыны шақыру",
        "participants_count": "Қатысушылар саны",
        "participants": "Қатысушылар",
        "add_participants": "Қатысушыларды қосу",
        "create_group": "Команда қосу",
        "update_group": "Команданы өңдеу",
        "title_group": "Команда атауы",
        "description": "Сипаттама",
        "group_type": "Команда түрі",
        "add_soc_link": "Әлеуметтік желіні қосу",
        "avatar_group": "Аватар",
        "avatar_project": "Жоба аватары",
        "create": "Құру",
        "upload_file": "Файлды жүктеу",
        "create_post": "Жарияланым жасау",
        "edit_post": "Жарияланымды өңдеу",
        "title_news": "Жаңалық атауы",
        "image": "Сурет",
        "type_soc_link": "Әлеуметтік желі түрі",
        "place": "Орын",
        "invite": "Шақыру",
        "make_appointment": "Қабылдауға жазылу",
        "more": "Толығырақ",
        "participantss": "қатысушылар",
        "director": "Құрылтайшы",
        "tasks_complete": "Аяқталған тапсырмалар",
        "no_data": "Деректер жоқ",
        "today_no_events": "Бүгін оқиғалар жоқ",
        "link": "Сілтеме",
        "information_edited": "Ақпарат өзгертілді",
        "group_created": "Команда сәтті құрылды",
        "fill_all_fields": "Барлық өрістерді толтырыңыз",
        "invalid_link": "Жарамсыз сілтеме",
        "join_group": "Командаға қосылу",
        "invite_group": "Командаға шақыру",
        "cancel": "Болдырмау",
        "fill_FIO": "Аты-жөнін енгізіңіз",
        "news": "Жаңалықтар",
        "event": "Оқиға",
        "task": "Тапсырмалар",
        "role": "Рөл",
        "group": "Команда",
        "open": "Ашу",
        "projects": "Жобалар",
        "project": "Жоба",
        "create_project": "Жоба қосу",
        "update_project": "Жобаны өңдеу",
        "project_not": "Жобалар жоқ",
        "group_not": "Команда жоқ",
        "title_project": "Жоба атауы",
        "deadline_project": "Жобаның мерзімі",
        "deadline_project2": "Мерзім",
        "date_start_plan": "Басталу күні",
        "finished_project": "Жобаны аяқтау",
        "resume_project": "Жобаны жалғастыру",
        "time": "Уақыт",
        "time_is_required": "Уақытты көрсету қажет",
        "group-and-project": "Командалар мен жобалар",
        "group&project": "Командалар мен жобалар",
        "workgroups": "Командалар",
        "project_created": "Жоба құрылды",
        "project_finished": "Жоба аяқталды",
        "enter_the_conference": "Конференцияға кіру",
        "meetings": "Конференция",
        "invite_link": "Шақыру сілтемесі",
        "participant": "қатысушы",
        "participant2": "қатысушы",
        "participant3": "қатысушылар",
        "event_name": "Оқиға атауы",
        "periud_time": "Уақыт аралығы",
        "you_partisipants": "Сіз команданың мүшесісіз",
        "you_not_member_group": "Сіз енді команданың мүшесі емессіз",
        "successful": "Сәтті",
        "news_created": "Жаңалық жасалды",
        "member_delete": "Қатысушы жойылды",
        "date_validation_min_max": "Басталу күні аяқталу күнінен ерте болмауы керек",
        "event_created": "Оқиға жасалды",
        "member_is_moderator": "Қатысушы модератор болып тағайындалды",
        "exit": "Шығу",
        "load_more": "Көбірек жүктеу",
        "error": "Қате",
        "update": "Сақтау",
        "delete_image": "Суретті жою",
        "yes": "Иә",
        "no": "Жоқ",
        "field_require": "Міндетті толтыру",
        "with_chat": "Чатты қосу",
        "create_chat": "Чат құру",
        "open_chat": "Чатты ашу",
        "comments": "Пікірлер",
        "is_author": "Сіз авторсыз",
        "author": "Автор",
        "select": "Таңдау",
        "save": "Сақтау",
        "remove_partisipant": "Қатысушыны жою",
        "change_moderator": "Модератор етіп тағайындау",
        "users_not_fount": "Пайдаланушылар жоқ",
        "calendar": "Күнтізбе",
        "task_calendar": "Тапсырма",
        "event_date": "Оқиға күні",
        "kanban": "Канбан",
        "table": "Тізім",
        "warning": "Ескерту",
        "delete_confirm_text": "Шынымен {type} жойғыңыз келе ме?",
        "project_label": "жоба",
        "group_label": "команда",
        "close": "Жабу",
        "delete": "Жою",
        "project_delete": "Жоба жойылды",
        "group_delete": "Команда жойылды",
        "counterparty": "Контрагент",
        "select_counterparty": "Контрагентті таңдау",
        "program": "Бағдарлама",
        "select_program": "Бағдарламаны таңдау",
        "costing_object": "Есеп объектісі",
        "select_costing_object": "Есеп объектісін таңдау",
        "change": "Өзгерту",
        "analytics": "Статистика",
        "stat_empty": "Статистика үшін жеткілікті деректер жоқ",
        "sprints": "Спринттер",
        "sprint": "Спринт",
        "share": "Бөлісу",
        "all_budget": "Жалпы смета",
        "all_difficulty": "Орташа күрделілік бағасы",
        "files": "Файлдар",
        "interest": "Қызығушылықтар",
        "control_dates": "Тапсырма күндерін бақылау",
        "group_files": "Файлдар",
        "chat_files": "Чат файлдары",
        "select_organization": "Ұйымды таңдау",
        "organization": "Ұйым",
        "organization_name": "Ұйым атауы",
        "project_control": "Бұл опцияны таңдаған кезде тапсырма ішіндегі ішкі тапсырмалардың және жобадағы тапсырмалардың уақыт шекаралары бақыланады.",
        "logo_upload": "Логотипті жүктеп салу",
        "upload": "Жүктеп алу",
        "no_date": "Күн жоқ",
        "chat_created": "Чат сәтті жасалды!",
        "not_found": "Топ/жоба табылмады немесе жойылды",
        "group_share": "Топ",
        "add_task": "Тапсырма қосыңыз",
        "control_on": "Күнді басқару қосылды",
        "moderator": "Модератор",
        "at_files": "Тіркелген файлдар",
        "amount": "Жалпы сома:",
        "all_task": "Барлық тапсырмалар",
        "new": "Жаңа",
        "work": "Орындалуда",
        "pause": "Кідіртуде",
        "review": "Қаралуда",
        "rework": "Қайта өңдеу бойынша",
        "on_performance": "Орындау бойынша",
        "done": "Дайын",
        "overall_rating": "Жалпы рейтинг:",
        "my_task": "Менің тапсырмаларым",
        "templates": "Үлгілер",
        "project_templates": "Жоба үлгілері",
        "create_template": "Үлгі жасаңыз",
        "stage_name": "Кезең атауы",
        "stage_created": "Кезең құрылды",
        "template_saved_successfully": "Кезең сәтті сақталды",
        "delete_template?": "Үлгіні жою керек пе?",

        "create_stage": "Кезең қосу",
        "select_project_template": "Жоба үлгісін таңдаңыз",
        "new_project": "Жаңа жоба",
        "could_not_create_a_template": "Үлгі жасау мүмкін болмады",
        "could_not_save_a_template": "Үлгіні сақтау мүмкін болмады",
        "could_not_load_templates": "Үлгілерді жүктеу мүмкін болмады",
        "enter_the_template_name": "Үлгі атауын енгізіңіз",
        "please_fill_in_all_required_fields": "Барлық қажетті өрістерді толтырыңыз",
        "the_organization_using_the_template": "Үлгіні қолданатын ұйым",
        "template_saved_successfully": "Үлгі сәтті сақталды",
        "save_template": "Үлгіні сақтау",
        "completed": "Аяқталды",
        "project_list": "Список проектов",
        "project_gant": "Диаграмма Ганта"
    }
}