var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WidgetWrapper',{class:_vm.isMobile && 'mobile_widget',attrs:{"widget":_vm.widget}},[_c('div',{staticClass:"map_wrapper select-none"},[_c('a-spin',{attrs:{"spinning":_vm.filterLoading}},[_c('MapFilter',{ref:"mapFilter",attrs:{"mapData":_vm.mapData,"setFilters":_vm.setFilters,"regionsCache":_vm.regionsCache,"districts":_vm.districts,"summaryData":_vm.summary,"summaryLoader":_vm.summaryLoader,"getData":_vm.getData,"clearFilters":_vm.clearFilters,"getMapCenter":_vm.getMapCenter}})],1),_c('div',{staticClass:"country_map",class:[`zoom_level_${_vm.zoom}`, _vm.showFill],staticStyle:{"height":"600px"},attrs:{"id":"map"}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('l-map',{ref:"mapInstance",staticStyle:{"height":"600px"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"options":_vm.options,"max-bounds":_vm.maxBounds,"max-bounds-viscosity":0.5},on:{"zoomend":_vm.updateZoom,"update:zoom":_vm.getMapCenter,"update:center":_vm.getMapCenter}},[_c('l-tile-layer',{attrs:{"url":_vm.tileUrl,"attribution":_vm.tileAttribution}}),(!_vm.regionFilter)?_vm._l((_vm.filteredRegion),function(region,index){return _c('l-geo-json',{key:region.id,ref:'geoJson' + index,refInFor:true,attrs:{"geojson":region.geometry,"optionsStyle":_vm.defaultStyle,"options":{
                                className: 'geo_poligon',
                                onEachFeature: _vm.onEachFeatureFunction
                            }},on:{"click":function($event){return _vm.clickToRegion(region)}}})}):[(_vm.regionFilter)?_vm._l((_vm.regionFilter.district),function(district){return _c('l-geo-json',{key:district.id,attrs:{"geojson":district.geometry,"options":{
                                    className: 'geo_poligon',
                                    onEachFeature: _vm.onEachFeatureFunction2
                                },"optionsStyle":_vm.defaultStyle},on:{"click":function($event){return _vm.clickToDistrict(district)}}})}):_vm._e()],_c('v-marker-cluster',{staticStyle:{"width":"100px","background":"#fff"},attrs:{"options":_vm.clusterOptions}},[_vm._l((_vm.clusterPoints),function(item){return _vm._l((item.location_points),function(point){return _c('l-marker',{key:item.id+point.lat+point.lon,attrs:{"lat-lng":[point.lat, point.lon],"options":{ data: item }},on:{"click":function($event){return _vm.pointClickHandler(point, item)}}},[_c('l-icon',{attrs:{"iconSize":[50, 50],"class-name":`point_marker ${_vm.getMarkerColor(item)}`}},[_c('div',{staticClass:"point_marker__wrapper"},[_c('span',[_vm._v(_vm._s(item.total_value))])])])],1)})})],2),_c('l-control',{attrs:{"position":"topleft"}},[_c('div',{staticClass:"flex flex-col"},[_c('a-button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ inertia : true, duration : '[600,300]'}),expression:"{ inertia : true, duration : '[600,300]'}"}],class:!_vm.showTile && 'opacity-60',attrs:{"flaticon":"","content":"Переключить слой карты","icon":"fi-rr-map"},on:{"click":function($event){return _vm.toggleTile()}}}),(_vm.showTile)?_c('a-button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ inertia : true, duration : '[600,300]'}),expression:"{ inertia : true, duration : '[600,300]'}"}],class:[_vm.hideRegion && 'opacity-60', 'mt-1'],attrs:{"flaticon":"","content":"Отключить области и районы","icon":"fi-rr-layers"},on:{"click":function($event){return _vm.toggleRegion()}}}):_vm._e()],1)])],2)],1)],1)],1),_c('ViewDrawer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }