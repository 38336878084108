export default {
    sports: {
        "sportsFacilities": "Спорт нысандарының тізілімі",
        "add": "Нысан төлқұжатын қосу",
        "addTitle": "Нысан төлқұжатын қосу",
        "editTitle": "Нысан төлқұжатын өңдеу",
        "filterTitle": "Мәртебесі бойынша сүзу",
        "open": "Ашу",
        "more": "Толығырақ",
        "repair": "Жөндеу туралы ақпарат",
        "sections": "Секциялар/үйірмелер туралы ақпарат",
        "technical": "Материалдық-техникалық жабдықтау",
        "generateStat": "Есепті қалыптастыру",
        "noProjects": "Деректер жоқ",
        "objectPassport": "Нысан төлқұжаты",
        "organization": "Ұйым",
        "responsible": "Жауапты тұлға",
        "tabInformation": "Ақпарат",
        "tabDocuments": "Құжаттар",
        "tabChangeHistory": "Өзгерістер тарихы",
        "projectMainInfo": "Жоба туралы негізгі ақпарат",
        "repairNeed": "Жөндеуді қажет ету",
        "equipmentNeed": "Материалдық-техникалық жабдықтау қажет",
        "location": "Орналасқан жері",
        "ownershipType": "Меншік түрі",
        "government": "Мемлекеттік",
        "ownerOrganization": "Иесі ұйымның атауы",
        "bin": "БСН",
        "purpose": "Мақсаты",
        "sportsFacility": "Дене шынықтыру-спорт нысаны",
        "sportsFacilityType": "Спорттық құрылымның түрі",
        "sportsHall": "Спорт залы",
        "sportsFacilitySubtype": "Спорттық құрылымның кіші түрі",
        "educationalInstitutions": "Жалпы білім беру мекемелерінде",
        "constructionYear": "Салынған жылы",
        "capacity": "Өткізу қабілеті",
        "storeys": "Қабат саны",
        "area": "Ауданы",
        "owner": "Иесі",
        "r_date": "Соңғы жөндеу күні",
        "r_type": "Жөндеу түрі",
        "r_sum": "Жөндеу құны",
        "status": "Мәртебе",
        "formError": "Міндетті түрде толтырыңыз",
        "repairRequest": "Жөндеуге өтінім",
        "needRepair": "Жөндеу қажет",
        "yes": "Иә",
        "no": "Жоқ",
        "repairCost": "Жөндеу құны, млн теңге",
        "enterCost": "Құнын енгізіңіз",
        "comment": "Пікір",
        "enterComment": "Пікір енгізіңіз",
        "attachFiles": "Файлдарды тіркеу",
        "toReview": "Тексеруге жіберу",
        "saveChanges": "Өзгерістерді сақтау",
        "repairInfo": "Жөндеу туралы ақпарат",
        "repairDate": "Жөндеу күні",
        "selectDate": "Күнді таңдау",
        "repairType": "Жөндеу түрі",
        "selectFromList": "Тізімнен таңдаңыз",
        "addRepairInfo": "Жөндеу туралы ақпарат қосу",
        "equipmentList": "Жабдықтар тізімі",
        "cost": "Құны",
        "quantity": "Саны",
        "unitPrice": "Бірлік бағасы",
        "purchaseDate": "Сатып алу күні",
        "serviceLife": "Қызмет ету мерзімі",
        "condition": "Жағдайы",
        "files": "Файлдар",
        "gallery": "Галерея",
        "noHistory": "Тарих жоқ",
        "changeAuthor": "Өзгеріс авторы:",
        "full_sports_facilities_repair": "Ақпарат",
        "full_sports_facilities_files": "Құжаттар",
        "full_sports_facilities_history": "Тарих",
        "back_route": "Артқа қайту",
        "edit": "Өңдеу",
        "delete": "Жою",
        "delete_message": "Нысан төлқұжатын жойғыңыз келетініне сенімдісіз бе?",
        "cancel": "Бас тарту",
        "delete_success": "Нысан төлқұжаты сәтті жойылды",
        "all_object": "Барлық нысандар",
        "repair_price": "Жөндеудің жалпы құны",
        "all_sport_object": "Спорт нысандарының жалпы саны",
        "address_select": "Мекенжайды таңдау",
        "name": "Атауы",
        "lat": "Ендік",
        "lng": "Бойлық",
        "save": "Сақтау",
        "map_search": "Карта бойынша іздеу",
        "map_select_point": "Выберите точку на карте или введите адрес в поиске",
        "category": "Санат",
        "subcategory": "Кіші санат",
        "sport_type": "Спорт түрі",
        "message_compliance": "Республикалық жарыстар өткізуге нысанның сәйкестігі",
        "object_pasport_updated": "Нысан төлқұжаты сәтті жаңартылды",
        "object_pasport_created": "Нысан төлқұжаты сәтті жасалды",
        "field_empty": "Міндетті өрістерді толтырыңыз",
        "object_name": "Нысан төлқұжатының атауы",
        "cult_sports": "Жобадағы спорт түрлері",
        "countryside": "Ауылдық жер",
        "region": "Облыс / Республикалық маңызы бар қала",
        "district": "Аудан / Республикалық маңызы бар қала",
        "akimat": "Әкімдік",
        "settlement": "Елді мекен",
        "village": "Ауыл",
        "provide_address": "Мекенжайды көрсетіңіз",
        "org_name": "Ұйымның атауы",
        "o_type": "Білім беру мекемесінің түрі",
        "sport_school_type": "Спорт мектебінің түрі",
        "area2": "Ауданы, м2",
        "capacity2": "Өткізу қабілеті, адам",
        "create_object": "Нысан құру",
        "unknown_error": "Белгісіз қате",
        "map_view": "Спорт нысандарының картасы",
        "not_specified": "Белгіленген жоқ",
        "downloadReport": "Есепті жүктеп алыңыз",
        "reportFileName": "Спорт нысандарының тізілімі, есеп {date}"
    }
}