<template>
    <div>
        <CreateGroup />
        <MainPage :isProject="false"/>
    </div>
</template>

<script>
import store from "./store/index"
import CreateGroup from './CreateGroup'
import MainPage from './MainPage/index.vue'

export default {
    name: "GroupsInit",
    components: {
        CreateGroup,
        MainPage
    },
    data() {
        return {
            buttonSize: 'large'
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    created() {
        if(!this.$store.hasModule('workgroups')) {
            this.$store.registerModule("workgroups", store)
            this.$store.commit('ADD_CONNECTED_MODULES', 'workgroups')
        }
    }
}
</script>