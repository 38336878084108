export default () => ({
    tableCols: {},
    tableList: {},
    focusCache: {},
    sortModel: {},
    tableColsRules: {},
    tableUpdate: {},
    tableUpdateSelect: {},
    tableRowFocus: {},
    
    tablesInfo: {},    
    tableRows: {},
})