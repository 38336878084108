<template>
    <div>
        <div class="flex items-start" :class="!multiple && isUser && 'selectable'" @click="select">
            <div class="flex items-center mr-2">
                <template v-if="multiple">
                    <a-checkbox 
                        size="large" 
                        class="text-base mr-2 checkbox" 
                        :checked="checkSelected(item)"
                        @click="itemSelect(item, true)" />
                </template>
                <a-avatar
                    icon="user"
                    :src="avatarSrc"
                    :class="!multiple && isUser && 'cursor-pointer'" />
            </div>
            <div>
                <p class="username pt-1.5">
                    {{ item.full_name || item.name }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserItem',
    props: {
        item: {
            type: Object,
            required: true,
        },
        multiple: {
            default: false
        },
        checkSelected: {
            type: Function,
            default: () => {}
        },
        itemSelect: {
            type: Function,
            default: () => {}
        },
    },
    data() {
        return {
            expanded: false,

            infiniteId: new Date(),
            organizationInfiniteId: new Date(),

            users: {
                results: [],
                next: true
            },
            usersParams: {
                page: 1,
                page_size: 10,
                page_name: this.pageName,
            },

            organizations: {
                results: [],
                next: this.item.structural_division_count
            },
            organizationsParams: {
                page: 1,
                page_size: 10,
                page_name: this.pageName,
                filters: { relation_type_id: 'structural_division' }
            },
        }
    },
    computed: {

        selectedUsers() {
            return this.metadata?.value?.[this.metadata?.key] || []
        },
        isAllOrganizationsLoaded() {
            return !this.organizations.next 
        },
        avatarSrc() {
            return this.item?.avatar?.path || this.item.logo
        },
        isUser() {
            return this.item.username
        },
        checked() {
            if (this.isUser) {
                const parentOrganization = this.selectedUsers.find(item => item.parent.id === this.parent.id)
                if (parentOrganization) {
                    return parentOrganization.users.some(user => user.id === this.item.id)
                }
                return false
            }
            // if organization
            const organization = this.selectedUsers.find(item => item.parent.id === this.item.id)
            if (organization) {
                if (organization.users?.length > 0) {
                    return Boolean(organization.users.length)
                }
                return organization?.checked || false
            }
            return false
        },
        indeterminate() {
            if (this.isUser) return false
            const organization = this.selectedUsers.find(item => item.parent.id === this.item.id)
            if (organization?.users?.length) 
                return organization.users.length > 0 
                    && organization.users.length < organization.userCount
            return false
        },
    },
    methods: {
        toggleExpandedState() {
            this.expanded = !this.expanded
            if (this.expanded) { this.reset() }
        },
        reset() {
            this.infiniteId = new Date()
            this.users = { results: [] }
            this.usersParams.page = 1
            
            this.organizationInfiniteId = new Date()
            this.organizations = { results: [] }
            this.organizationsParams.page = 1
        },
        async usersInfiniteHandler($state) {
            const url = `/users/my_organizations/${this.item.id}/users_short/`
            this.$http.get(url, { params: this.usersParams })
                .then(({ data }) => {
                    data.results.unshift(...this.users.results)
                    this.users = data

                    if (data.next) {
                        this.usersParams.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(error => {
                    this.$message.error('Не удалось получить список пользователей')
                    console.error(error)
                    $state.complete()
                })         
        },
        organizationsInfiniteHandler($state) {
            const url = `/users/my_organizations/${this.item.id}/relations/`
            this.$http.get(url, { params: this.organizationsParams })
                .then(({ data }) => {
                    data.results.unshift(...this.organizations.results)
                    this.organizations = data
                    if (data.next) {
                        this.organizationsParams.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(error => {
                    this.$message.error('Не удалось получить список дочерних организаций')
                    console.error(error)
                    $state.complete()
                })         
        },
        select() {
            if (!this.multiple) {
                this.itemSelect(this.item, true)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
:deep {
    .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border-radius: 4px;
    }
}

.list_item {
    padding-left: 35px;
    & + & {
        margin-top: 20px;
    }
}


.item_angle {
    position: relative;
    z-index: 1000;

    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 20px;
    
    margin-right: 15px;

    color: var(--primaryColor);
    transition: transform 0.2s ease;
    cursor: pointer;
}

.item_angle.expanded {
    transform: rotate(180deg);
}

.checkbox {
    position: relative;
    z-index: 1000;
}

.username {
    transition: color 0.2s ease;
}

.selectable {
    .username,
    .organization_name {
        cursor: pointer;
    }
}
.selectable:hover {
    .username,
    .organization_name {
        color: var(--primaryColor);
    }
}

</style>