export default {
    "inquiries": {
        "issue_category": "Категория вопроса",
        "sent_to": "Направлено",
        "inquiry_text": "Текст обращения",
        "no_data": "Отсутствует",
        "history_changes": "История изменений",
        "initial_risk_assessment_by": "Первичную оценку риска обращения сделал",
        "assessment_criteria": "Критерии оценки",
        "yes": "Да",
        "no": "Нет",
        "head_of_apparatus": "Руководитель аппарата",
        "main_leader_or_deputies": "Первый руководитель/Заместители",
        "confirm_delete_title": "Вы действительно хотите удалить оценку риска?",
        "delete": "Удалить",
        "cancel": "Закрыть",
        "delete_success": "Оценка риска удалена",
        "sorting": "Сортировка",
        "new": "Новые",
        "processed": "Обработанные",
        "current_month": "Текущий месяц",
        "current_year": "Текущий год",
        "current_day": "Текущий день",
        "period": "Период",
        "start": "Начало периода",
        "end": "Конец периода",
        "total": "Всего",
        "points_0": "0 баллов",
        "points_1_2": "1-2 баллов",
        "points_3_5": "3-5 баллов",
        "points_6_10": "6-10 баллов",
        "organization": "Организация",
        "with_departments": "Со структурными подразделениями",
        "departments_only": "Структурные подразделения",
        summary: "Характер обращения",
        noData: "Нет данных",
        sentTo: "Направлено",
        inquiryText: "Текст обращения",
        evaluationCriteria: "Критерии оценки",
        sortByNumberAsc: "По номеру А..я",
        sortByNumberDesc: "По номеру Я..а",
        sortNewestFirst: "Сначала новые",
        sortOldestFirst: "Сначала старые",
        "with_subdivisions": "Со структурными подразделениями",
        "subdivisions": "Структурные подразделения",
        "period_start": "Начало периода",
        "period_end": "Конец периода",
        "responsible": "Ответственный",
        "issueDate": "Дата поступления обращения:",
        "datePlaceholder": "Выберите дату",
        "orgSelect": "Выберите организацию",
        "resNumber": "Номер обращения:",
        "resNumberPlaceholder": "Введите номер обращения",
        "resType": "Вид обращения:",
        "resTypePlaceholder": "Выберите вид обращения",
        "issueCategoryPlaceholder": "Укажите категорию вопроса",
        "resSt": "Направлено на рассмотрение:",
        "resText": "Текст обращения (при необходимости):",
        "resCr": "Выделите критерии риска обращения:",
        "resTotal": "Итоговое значение:",
        "touch": "Нажмите",
        "cancel": "Отменить",
        "save": "Сохранить",
        "create": "Создать",
        "field_required": "Обязательно для заполнения",
        "resUpdated": "Обращение обновлено",
        "resCreated": "Обращение создано",
        "resError": "Ошибка",
        "dataError": "Некорректные данные",
        "resEdit": "Редактировать обращение",
        "resAdd": "Добавить обращение",
        "provide_address": "Укажите адрес",
        "map_select": "Указать на карте",
        "spec": "Специалист",
        "inc_date": "Дата обращения",
        "inc_view": "Просмотр обращения",
        "information": "Информация",
        "treatmentCategory": "Категория обращения",
        "statusFilter": "Фильтровать по статусу",
        "orgFilter": "Фильтровать по иерархии",
        "sortList": "Сортировать список",
        "dateFilter": "Фильтровать по сроку",
        "filters": "Фильтры",
        "total_request": "Всего обращений"
    }
}