<template>
    <WidgetWrapper :widget="widget" :class="isMobile && 'mobile_widget'">
        <div class="map_wrapper select-none">
            <a-spin :spinning="filterLoading">
                <MapFilter 
                    ref="mapFilter"
                    :mapData="mapData" 
                    :setFilters="setFilters"
                    :regionsCache="regionsCache"
                    :districts="districts"
                    :summaryData="summary"
                    :summaryLoader="summaryLoader"
                    :getData="getData"
                    :clearFilters="clearFilters"
                    :getMapCenter="getMapCenter" />
            </a-spin>
            <div 
                id="map" 
                class="country_map" 
                :class="[`zoom_level_${zoom}`, showFill]"
                style="height: 600px;">
                <a-spin :spinning="loading">
                    <l-map 
                        ref="mapInstance" 
                        :zoom="zoom" 
                        :center="center" 
                        :options="options" 
                        style="height: 600px;"
                        :max-bounds="maxBounds"
                        :max-bounds-viscosity="0.5"
                        @zoomend="updateZoom"
                        @update:zoom="getMapCenter"
                        @update:center="getMapCenter">
                        <l-tile-layer 
                            :url="tileUrl"
                            :attribution="tileAttribution" />
                        <template v-if="!regionFilter">
                            <l-geo-json
                                v-for="(region, index) in filteredRegion"
                                :key="region.id"
                                :geojson="region.geometry"
                                :optionsStyle="defaultStyle"
                                :ref="'geoJson' + index"
                                :options="{
                                    className: 'geo_poligon',
                                    onEachFeature: onEachFeatureFunction
                                }"
                                @click="clickToRegion(region)">
                            </l-geo-json>
                        </template>
                        <template v-else>
                            <template v-if="regionFilter">
                                <l-geo-json
                                    v-for="district in regionFilter.district"
                                    :key="district.id"
                                    :geojson="district.geometry"
                                    :options="{
                                        className: 'geo_poligon',
                                        onEachFeature: onEachFeatureFunction2
                                    }"
                                    :optionsStyle="defaultStyle"
                                    @click="clickToDistrict(district)" />
                            </template>
                        </template>
                        <v-marker-cluster 
                            :options="clusterOptions"
                            style="width: 100px; background: #fff;">
                            <template v-for="item in clusterPoints" >
                                <l-marker 
                                    v-for="point in item.location_points"
                                    :key="item.id+point.lat+point.lon" 
                                    :lat-lng="[point.lat, point.lon]"
                                    :options="{ data: item }"
                                    @click="pointClickHandler(point, item)">   
                                    <l-icon
                                        :iconSize="[50, 50]"
                                        :class-name="`point_marker ${getMarkerColor(item)}`">
                                        <div class="point_marker__wrapper">
                                            <span>{{ item.total_value }}</span>
                                        </div>
                                    </l-icon>
                                </l-marker>
                            </template>
                        </v-marker-cluster>
                        <l-control position="topleft" >
                            <div class="flex flex-col">
                                <a-button 
                                    flaticon 
                                    v-tippy="{ inertia : true, duration : '[600,300]'}"
                                    content="Переключить слой карты"
                                    :class="!showTile && 'opacity-60'"
                                    icon="fi-rr-map"
                                    @click="toggleTile()" />
                                <a-button 
                                    v-if="showTile"
                                    flaticon 
                                    v-tippy="{ inertia : true, duration : '[600,300]'}"
                                    content="Отключить области и районы"
                                    :class="[hideRegion && 'opacity-60', 'mt-1']"
                                    icon="fi-rr-layers"
                                    @click="toggleRegion()" />
                            </div>
                        </l-control>
                    </l-map>
                </a-spin>
            </div>
        </div>
        <ViewDrawer />
    </WidgetWrapper>
</template>

<script>
import { LMap, LTileLayer, LGeoJson, LMarker, LControl, LIcon } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import WidgetWrapper from '../WidgetWrapper.vue'
import L, { latLng } from 'leaflet'
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster'
import "leaflet.markercluster/dist/MarkerCluster.css"
import MapFilter from './components/Inquiries/MapFilter'
import axios from 'axios'
import ViewDrawer from './components/Inquiries/ViewDrawer'
import { addDataLazy, maxBounds, expandBounds, generateIcon, getMarkerColor, iconCreateFunction } from './components/Inquiries/utils.js'
let timer;
export default {
    props: {
        widget: {
            type: Object,
            required: true
        }
    },
    components: {
        WidgetWrapper,
        LMap,
        LTileLayer,
        LGeoJson,
        LMarker,
        MapFilter,
        LControl,
        LIcon,
        ViewDrawer,
        'v-marker-cluster': Vue2LeafletMarkercluster
    },
    computed: {
        showFill() {
            return this.zoom <= 8 ? '' : 'hide_fill'
        },
        onEachFeatureFunction() {
            return (feature, layer) => {
                if(this.zoom <= 9) {
                    layer.bindTooltip(
                        `<div>${feature.name}</div>`,
                        { permanent: false, sticky: true }
                    )
                }
            }
        },
        onEachFeatureFunction2() {
            return (feature, layer) => {
                if(feature?.name && this.zoom <= 9) {
                    layer.bindTooltip(
                        `<div>${feature.name}</div>`,
                        { permanent: false, sticky: true }
                    )
                }
            }
        },
        mapInstance() {
            return this.$refs.mapInstance.mapObject
        },
        isMobile() {
            return this.$store.state.isMobile;
        },
        kzMap() {
            return this.zoom < 9 ? true : false
        },
        tileUrl() {
            if(!this.showTile && this.kzMap)
                return ''
            return 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        },
        filteredRegion() {
            if(this.regionFilter) {
                const find = this.regions.find(f => f.id === this.regionFilter.id)
                return [find]
            }
            return this.regions
        },
        defaultStyle() {
            if(this.showTile) {
                if(this.hideRegion) {
                    return {
                        weight: 0,
                        fillOpacity: 0
                    }
                } else {
                    return {
                        color: '#1e65bf',
                        weight: 1,
                        fillColor: '#1e65bf',
                        fillOpacity: this.zoom <= 8 ? 0.1 : 0
                    }
                }
                
            } else {
                if(this.zoom <= 8) {
                    return {
                        color: '#92a6b8',
                        weight: 1,
                        fillColor: '#f0f2f6',
                        fillOpacity: 1
                    }
                } else {
                    return {
                        color: '#1890ff',
                        weight: 1,
                        fillColor: '#f0f2f6',
                        fillOpacity: 0
                    }
                }
            }
        }
    },
    data() {
        return {
            axiosCancel: null,
            tileAttribution: '',
            regions: [],
            summary: null,
            summaryLoader: false,
            clusterOptions: {
                iconCreateFunction: iconCreateFunction
            },
            loading: false,
            zoom: 5,
            districts: [],
            mapData: [],
            filters: {},
            filterLoading: false,
            clusterPoints: [],
            center: [48.0196, 66.9237],
            regionFilter: null,
            showTile: false,
            hideRegion: false,
            regionsCache: [],
            options: {
                minZoom: 4,
                attributionControl: false
            },
            maxBounds: maxBounds,
            hoverStyle: {
                color: '#1e65bf',
                weight: 1,
                fillColor: '#1e65bf',
                fillOpacity: 0.2,
            }
        }
    },
    created() {
        const initTile = localStorage.getItem('MapShowTile')
        if(initTile)
            this.showTile = JSON.parse(initTile)
        const initRegion = localStorage.getItem('MapRegion')
        if(initRegion)
            this.hideRegion = JSON.parse(initRegion)

        this.getData(true)
        this.getMapCenter()
    },
    methods: {
        generateIcon,
        getMarkerColor,
        pointClickHandler(point, item) {
            if(this.zoom <= 8) {
                this.$nextTick(() => {
                    this.$refs.mapInstance.mapObject.flyTo(latLng(point.lat, point.lon), 16, {
                        duration: 1.5,
                        easeLinearity: 1.5
                    })
                    this.$refs.mapInstance.mapObject.invalidateSize()
                })
            }
            this.$nextTick(() => {
                this.$refs.mapFilter.getInfo(item)
            })
        },
        getMapCenter() {
            this.$nextTick(() => {
                this.center = this.$refs['mapInstance'].mapObject.getCenter()
            })
            if(this.axiosCancel) {
                this.axiosCancel.cancel()
                this.axiosCancel = null
            }
            clearTimeout(timer)
            timer = setTimeout(() => {
                this.$nextTick(async () => {
                    if(this.$refs['mapInstance']?.mapObject) {
                        try {
                            this.summaryLoader = true
                            const axiosSource = axios.CancelToken.source()
                            this.axiosCancel = axiosSource
                            const map = this.$refs['mapInstance'].mapObject
                            const lat__gte = map.getBounds().getSouth()
                            const lat__lte = map.getBounds().getNorth()
                            const lon__gte = map.getBounds().getWest()
                            const lon__lte = map.getBounds().getEast()
                            const params = {
                                page_size: 'all',
                                lat__gte,
                                lat__lte,
                                lon__gte,
                                lon__lte
                            }
                            if(this.filters.issue_date_gte)
                                params.issue_date_gte = this.$moment(this.filters.issue_date_gte).format('YYYY-MM-DD')
                            if(this.filters.issue_date_lte)
                                params.issue_date_lte = this.$moment(this.filters.issue_date_lte).format('YYYY-MM-DD')
                            if(this.filters.categories?.length)
                                params.categories = this.filters.categories.join(',')
                            if(this.filters.total_value?.length)
                                params.total_value = this.filters.total_value.join(',')
                            if(this.filters.region)
                                params.region = this.filters.region
                            if(this.filters.district)
                                params.district = this.filters.district
                            const { data } = await this.$http.post('risk_assessment/points/', params)
                            if(data) {
                                this.clusterPoints = data.results
                                this.summary = data.summary
                            } else {
                                this.clusterPoints = []
                                this.summary = null
                            }
                        } catch(e) {
                            console.log(e)
                        } finally {
                            this.summaryLoader = false
                        }
                    }
                })
            }, 500)
        },
        setFilters(filters) {
            this.filters = filters
            if(filters.region) {
                const find = this.regionsCache.find(f => f.id === filters.region)
                if(find)
                    this.zoomToRegion(find)
            } else {
                if(this.regionFilter) 
                    this.clearFilters()
            }
        },
        clearFilters() {
            this.regionFilter = null
            this.setDefaultPosition()
        },
        async getData(init = false) {
            try {
                this.loading = true
                this.filterLoading = true
                const params = {}
                if(this.filters?.region)
                    params.parent = this.filters.region
                const { data } = await this.$http.get('/catalogs/location_admin_area/', { params })
                if(data) {
                    if(init) {
                        this.regionsCache = data
                    }
                    this.mapData = data
                    if(params.parent) {
                        const find = this.regionsCache.find(f => f.id === params.region)
                        this.districts = data.results
                        if(find)
                            this.zoomToRegion(find)
                    } else {
                        if(this.districts.length)
                            this.districts = []
                        if(this.regionFilter)
                            this.regionFilter = null
                        this.setDefaultPosition()
                        addDataLazy(
                            this.regions,
                            data,
                            item => ({
                                ...item,
                                geometry: {
                                    ...item.geometry,
                                    ...item
                                }
                            })
                        )
                    }
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
                this.filterLoading = false
            }
        },
        toggleTile() {
            this.showTile = !this.showTile
            localStorage.setItem('MapShowTile', JSON.stringify(this.showTile))
        },
        toggleRegion() {
            this.hideRegion = !this.hideRegion
            localStorage.setItem('MapRegion', JSON.stringify(this.hideRegion))
        },
        setDefaultPosition() {
            this.zoom = 5
            this.center = [48.0196, 66.9237]
            this.maxBounds = maxBounds
        },
        updateZoom(event) {
            this.zoom = event.target.getZoom()
            //if(this.zoom >= 7)
            //this.getMapDestricts()
            if(this.zoom <= 5 && this.regionFilter) {
                this.regionFilter = null
                this.maxBounds = maxBounds
                if(this.filters?.region)
                    this.filters.region = null
                if(this.filters?.district)
                    this.filters.district = null
                this.$refs.mapFilter.setRegion(null)
                this.$refs.mapFilter.setDistrict(null)
            }
        },
        generateRegionDist(region) {
            this.$nextTick(async () => {
                try {
                    this.loading = true
                    const bounds = L.geoJSON(region.geometry).getBounds()
                    this.$refs['mapInstance'].fitBounds(bounds)
                    this.maxBounds = expandBounds(bounds, 2.0)
                    const { data } = await this.$http.get('/catalogs/location_admin_area/', { 
                        params: {
                            parent: region.id
                        }
                    })
                    if(data) {
                        this.regionFilter = {
                            ...region,
                            district: []
                        }
                        addDataLazy(
                            this.regionFilter.district,
                            data,
                            item => ({
                                ...item,
                                geometry: {
                                    ...item.geometry,
                                    ...item
                                }
                            }),
                            4
                        )
                        setTimeout(() => {
                            const find = this.regionFilter.district.find(f => f.id === this.filters.district)
                            if(find && this.filters.district)
                                this.zoomToDistrict(find)
                        }, 500)
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            })
        },
        zoomToRegion(region) {
            this.generateRegionDist(region)
        },
        async clickToRegion(region) {
            this.$set(this.filters, 'region', region.id)
            this.$nextTick(() => {
                this.$refs.mapFilter.setRegion(region.id)
            })
            this.generateRegionDist(region)
        },
        clickToDistrict(district) {
            this.$set(this.filters, 'district', district.id)
            this.$nextTick(() => {
                this.$refs.mapFilter.setDistrict(district.id)
            })
            if (district.geometry) {
                this.$nextTick(() => {
                    const bounds = L.geoJSON(district.geometry).getBounds()
                    this.$refs['mapInstance'].fitBounds(bounds)
                })
            }
        },
        zoomToDistrict(district) {
            if (district.geometry) {
                this.$nextTick(() => {
                    const bounds = L.geoJSON(district.geometry).getBounds()
                    this.$refs['mapInstance'].fitBounds(bounds)
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.map_wrapper{
    display: grid;
    gap: 20px;
    grid-template-columns: 400px 1fr;
    width: 100%;
    overflow: auto;
    height: 100%;
}
.country_map{
    width: 100%;
    &::v-deep{
        .point_marker{
            display: flex;
            align-items: center;
            justify-content: center;
            &::after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                opacity: 0.4
            }
            &__wrapper{
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                position: relative;
                
                span{
                    position: relative;
                    z-index: 10;
                }
            }
            &.orange {
                &::after{
                    background: #ff8812;
                }
                .point_marker__wrapper{
                    background: #ff8812;
                }
            }
            &.red {
                &::after{
                    background: #ff4e46;
                }
                .point_marker__wrapper{
                    background: #ff4e46;
                }
            }
            &.white {
                &::after{
                    background: #A9B3BF;
                }
                .point_marker__wrapper{
                    background: #A9B3BF;
                }
            }
            &.yellow {
                &::after{
                    background: #fee933;
                }
                .point_marker__wrapper{
                    background: #fee933;
                }
            }
        }
        .cluster_wrapper {
            position: relative;
            width: 40px;
            height: 40px;
            background: #fff;
            border-radius: 50%;
            .cluster_label {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .circle-container {
            position: relative;
            width: 40px;
            height: 40px;
            background: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .circular-chart {
            transform: rotate(-90deg);
            width: 100%;
            height: 100%;
        }
        .circle {
            fill: none;
            stroke-width: 4;
        }
        .circle.orange {
            stroke: #ff8812;
        }
        .circle.red {
            stroke: #ff4e46;
        }
        .circle.white {
            stroke: #A9B3BF;
        }
        .circle.yellow {
            stroke: #fee933;
        }
        .circle-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 12px;
        }
        .ant-spin-container,
        .ant-spin-nested-loading{
            height: 100%;
        }
        .leaflet-container{
            background: #fff!important;
        }
        &:not(.hide_fill) {
            .geo_poligon{
                outline: none;
                &:hover{
                    fill: #1e65bf;
                    fill-opacity: 0.2;
                    stroke: #1e65bf;
                }
            }
        }
        .region_marker{
            width: initial!important;
            height: initial!important;
            text-align: center;
            font-size: 16px!important;
            margin-left: -50px!important;
            margin-top: -20px!important;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            pointer-events: none;
            .region_name{
                color: #000;
                min-width: 100px;
            }
            .region_stat{
                color: var(--blue);
            }
        }
    }
    &.zoom_level_4{
        &::v-deep{
            .region_marker{
                font-size: 10px!important;
            }
        }
    }
}
</style>