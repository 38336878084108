<template>
    <div>
        <div class="map_filters">
            <a-spin 
                :spinning="summaryLoader" 
                size="small">
                <div class="mb-2" style="font-size: 16px;color:#000;">
                    Всего: {{ summaryLength }}
                </div>
            </a-spin>
            <a-form-model
                ref="filterForm"
                :model="filter">
                <div class="grid gap-2 grid-cols-1 xl:grid-cols-2">
                    <a-form-model-item ref="region" label="Области" prop="region" class="mb-4">
                        <a-select
                            v-model="filter.region"
                            size="large"
                            show-search
                            allowClear
                            :filter-option="filterOption"
                            class="w-full">
                            <a-select-option v-for="item in regions" :value="item.id" :key="item.id">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="district" label="Районы" prop="district" class="mb-4">
                        <a-select
                            v-model="filter.district"
                            size="large"
                            show-search
                            allowClear
                            :loading="districtLoading"
                            :disabled="filter.region ? false : true"
                            :filter-option="filterOption"
                            class="w-full">
                            <a-select-option v-for="item in districtsList" :value="item.id" :key="item.id">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <a-button 
                    type="primary" 
                    size="large" 
                    class="mb-2"
                    block
                    @click="setFilter()">
                    Применить фильтр
                </a-button>
                <a-button 
                    type="primary" 
                    size="large" 
                    ghost 
                    block
                    @click="clearFilter()">
                    Очистить фильтр
                </a-button>
            </a-form-model>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    props: {
        mapData: {
            type: Array,
            default: () => []
        },
        getData: {
            type: Function,
            default: () => {}
        },
        getMapCenter: {
            type: Function,
            default: () => {}
        },
        regionsCache: {
            type: Array,
            default: () => []
        },
        setFilters: {
            type: Function,
            default: () => {}
        },
        districts: {
            type: Array,
            default: () => []
        },
        summaryLength: {
            type: Number,
            default: 0
        },
        summaryLoader: {
            type: Boolean,
            default: false
        },
        clearFilters: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        regions() {
            if(this.regionsCache?.length)
                return this.regionsCache
            return []
        },
        districtsOpts() {
            if(this.districts?.length)
                return this.districts
            return this.districtsList
        }
    },
    data() {
        return {
            districtLoading: false,
            districtsList: [],
            filter: {
                region: null,
                district: null
            }
        }
    },
    watch: {
        'filter.region'(val) {
            if(val)
                this.changeRegion(val)
            else
                this.districtsList = []
        }
    },
    methods: {
        async changeRegion(region) {
            try {
                this.districtLoading = true
                const { data } = await this.$http.get('/catalogs/location_admin_area/', { 
                    params: {
                        parent: region
                    }
                })
                if(data) {
                    this.districtsList = data
                }
            } catch(e) {
                console.log(e)
                this.districtsList = []
            } finally {
                this.districtLoading = false
            }
        },
        setFilter() {
            this.inquiries = null
            this.setFilters(this.filter)
            this.getMapCenter()
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        setRegion(value) {
            this.inquiries = null
            this.filter.region = value
        },
        setDistrict(value) {
            this.inquiries = null
            this.filter.district = value
        },
        clearFilter() {
            this.filter = {
                region: null,
                district: null
            }
            this.inquiries = null
            this.districtsList = []
            this.setFilters({})
            this.clearFilters()
            this.getMapCenter()
        }
    }
}
</script>

<style lang="scss" scoped>
.map_filters{
    background: #FAFAFA;
    border-radius: 8px;
    padding: 15px;
}
</style>