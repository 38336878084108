<template>
    <a-drawer
        placement="right"
        class="chat_info_drawer"
        :width="drawerWidth"
        :visible="visible"
        :destroyOnClose="true"
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <div class="drawer_header flex items-center justify-between truncate w-full">
            <div class="flex items-center">
                <div class="awatar_wrapper pr-2">
                    <a-avatar
                        v-if="activeChat.is_public"
                        :size="32"
                        :style="activeChat.color ? `backgroundColor:${activeChat.color}` : 'backgroundColor: #cccccc'">
                        {{ avatarText }}
                    </a-avatar>
                    <a-avatar
                        v-else
                        :size="32"
                        :style="activeChat.color ? `backgroundColor:${activeChat.color}` : 'backgroundColor: #cccccc'"
                        :src="activeChat.recipient && activeChat.recipient.avatar && activeChat.recipient.avatar.path ? activeChat.recipient.avatar.path : null">
                        {{ avatarText }}
                    </a-avatar>
                </div>
                <div class="name truncate w-full">
                    <div class="truncate w-full">{{ activeChat.name }}</div>
                    <div v-if="activeChat && activeChat.is_public" class="member">{{ chatMember }}</div>
                </div>
            </div>
            <div class="actions">
                <template v-if="activeChat && activeChat.is_public">
                    <template v-if="isModerator || isAuthor">
                        <a-button
                            v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }"
                            :content="!isMobile && $t('chat.edit')"
                            @click="changeChatName()"
                            icon="fi-rr-edit"
                            shape="circle"
                            ghost
                            flaticon
                            type="ui" />
                        
                        <UserDrawer
                            id="add_chat_users"

                            :metadata="{ key: 'users', value: addUsersForm.metadata }"
                            :changeMetadata="changeMetadata"
                            :submitHandler="addUsers"
                            v-model="addUsersForm.users"
                            multiple
                            inputSize="large"
                            :title="$t('chat.add_user')">
                            <template #openButton>
                                <a-button
                                    v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }"
                                    :content="!isMobile && $t('chat.add_user')"
                                    icon="fi-rr-user-add"
                                    shape="circle"
                                    class="ml-1"
                                    ghost
                                    flaticon
                                    type="ui" />
                            </template>
                        </UserDrawer>

                        <a-button
                            v-if="isAuthor"
                            v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }"
                            :content="!isMobile && $t('chat.remove')"
                            @click="showDeleteModal()"
                            icon="fi-rr-trash"
                            shape="circle"
                            class="ml-1"
                            ghost
                            flaticon
                            type="ui" />
                    </template>
                </template>
                <a-button
                    @click="visible = false"
                    icon="fi-rr-cross"
                    class="ml-1"
                    shape="circle"
                    ghost
                    flaticon
                    type="ui" />
            </div>
        </div>
        <div class="drawer_tabs">
            <a-tabs v-model="tab">
                <a-tab-pane key="members">
                    <span slot="tab">
                        {{$t('chat.participants')}}
                    </span>
                </a-tab-pane>
                <a-tab-pane key="task_list">
                    <span slot="tab">
                        <div class="flex items-center">
                            {{$t('chat.chat_tasks')}} <a-badge class="ml-1" :count="taskCount" />
                        </div>
                    </span>
                </a-tab-pane>
                <a-tab-pane key="chat_files">
                    <span slot="tab">
                        <div class="flex items-center">
                            {{$t('chat.chat_files')}} <a-badge class="ml-1" :count="fileCount" />
                        </div>
                    </span>
                </a-tab-pane>
                <a-tab-pane v-if="projectId" key="project_files">
                    <span slot="tab">
                        <div class="flex items-center">
                            {{$t('chat.project_files')}} <a-badge class="ml-1" :count="projectFileCount" />
                        </div>
                    </span>
                </a-tab-pane>
            </a-tabs>
        </div>
        <div class="drawer_body">
            <a-tabs class="body_tabs" :activeKey="tab">
                <a-tab-pane key="members" tab="">
                    <div v-if="loading" class="flex justify-center">
                        <a-spin />
                    </div>
                    <div
                        v-if="members && members.results"
                        class="chat_members">
                        <div
                            v-for="item in members.results"
                            :key="item.id"
                            class="item">
                            <UserCard
                                :userItem="item.user"
                                :showUserInfo="activeChat.is_public"
                                :info="item" />
                        </div>
                    </div>
                    <infinite-loading
                        @infinite="getMembers"
                        :identifier="activeChat.chat_uid"
                        v-bind:distance="20">
                        <div slot="spinner"></div>
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                    </infinite-loading>
                </a-tab-pane>
                <a-tab-pane key="chat_files" tab="" class="padding_tab">
                    <component
                        :active="tab"
                        :is="activeTab"
                        :id="activeChat.chat_uid"
                        :sourceId="fileSourceId"
                        :isFounder="true"
                        :isStudent="true" />
                </a-tab-pane>
                <a-tab-pane v-if="projectId" key="project_files" tab="" class="padding_tab">
                    <component
                        :active="tab"
                        :is="activeTab"
                        :id="activeChat.chat_uid"
                        :sourceId="fileSourceId"
                        :isFounder="true"
                        :isStudent="true" />
                </a-tab-pane>
                <a-tab-pane key="task_list" tab="" class="padding_tab flex flex-col">
                    <component
                        :active="tab"
                        :is="activeTab"
                        :id="activeChat.chat_uid"
                        :sourceId="fileSourceId"
                        :isFounder="true"
                        :isStudent="true" />
                </a-tab-pane>
            </a-tabs>
        </div>

        <a-modal
            :title="$t('chat.change_chat_name')"
            @cancel="changeName = false"
            destroyOnClose
            :visible="changeName">
            <a-form-model
                ref="nameForm"
                :model="form"
                :rules="rules">
                <a-form-model-item ref="name" prop="name">
                    <a-input 
                        size="large" 
                        v-model="form.name" 
                        :placeholder="$t('chat.press_chat_name')" />
                </a-form-model-item>
            </a-form-model>
            <template slot="footer">
                <a-button 
                    key="back" 
                    :block="isMobile ? true : false"
                    type="ui"
                    ghost
                    :size="isMobile ? 'large' : 'default'"
                    @click="changeName = false">
                    {{$t('chat.close')}}
                </a-button>
                <a-button 
                    :loading="nameLoading" 
                    key="submit" 
                    :class="isMobile && 'mt-2'"
                    :style="isMobile && 'margin-left: 0px;'"
                    :size="isMobile ? 'large' : 'default'"
                    :block="isMobile ? true : false"
                    type="primary" 
                    @click="submitForm()">
                    {{$t('chat.save')}}
                </a-button>
            </template>
        </a-modal>
        <AddMemberSidebar/>
    </a-drawer>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { declOfNum } from '../../utils'
import UserCard from '../UserCard.vue'
import InfiniteLoading from 'vue-infinite-loading'
import AddMemberSidebar from '../Sidebar/AddMemberSidebar.vue'
import UserDrawer from '@apps/DrawerSelect/index.vue'
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
export default {
    name: "ChatInfoDrawer",
    components: {
        InfiniteLoading,
        UserCard,
        AddMemberSidebar,
        UserDrawer
    },
    computed: {
        ...mapState({
            activeChat: state => state.chat.activeChat,
            sidebarInfo: state => state.chat.sidebarInfo,
            user: state => state.user.user,
            isMobile: state => state.isMobile
        }),
        ...mapGetters({
            chatMembers: 'chat/chatMembers'
        }),
        avatarText() {
            if(this.activeChat) {
                if(this.activeChat.is_public) {
                    return this.activeChat.name.charAt(0).toUpperCase()
                } else {
                    const n = this.activeChat.name.split(' ')
                    return `${n[0].charAt(0).toUpperCase()}${n[1] ? n[1].charAt(0).toUpperCase() : ''}${n[2] ? n[2].charAt(0).toUpperCase() : ''}`
                }
            }
            return ''
        },
        visible: {
            get() {
                if(this.sidebarInfo && this.activeChat && this.activeChat.is_public)
                    this.$socket.client.emit('chat_status_user', {chat_uid: this.activeChat.chat_uid})
                return this.sidebarInfo
            },
            set(val) {
                this.$store.commit('chat/TOGGLE_INFO_SIDEBAR', val)
            }
        },
        loading: {
            get(){
                return this.$store.state.chat.loadingInfoChat
            },
            set(val){
                this.$store.commit('chat/SET_LOADING_INFOCHAT', val)
            }
        },
        activeTab() {
            if(this.tab === 'task_list')
                return () => import('../ChatSidebar/Tasks.vue')
            if(['project_files', 'chat_files'].includes(this.tab))
                return () => import('@apps/vue2Files')

            return null
        },
        fileSourceId() {
            switch (this.tab) {
            case 'chat_files':
                return this.activeChat.id || this.activeChat.uid
            case 'project_files':
                return this.activeChat.workgroup.uid
            default:
                return 0
            }
        },
        projectId() {
            return this.activeChat?.workgroup?.uid
        },
        chatMember() {
            return this.activeChat.member_count + ' ' + declOfNum(this.activeChat.member_count, [this.$t('chat.participant'), this.$t('chat.participant2'), this.$t('chat.participant3')])
        },
        members() {
            return this.chatMembers(this.activeChat.chat_uid)
        },
        author() {
            return this.activeChat.chat_author
        },
        isAuthor() {
            if(this.author?.id === this.user.id)
                return true
            else
                return false
        },
        taskCount() {
            return this.tCount?.total 
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        isModerator() {
            return this.activeChat.is_moderator
        },
        drawerWidth() {
            if(this.isMobile) {
                return '100%'
            } else {
                if(this.tab === 'members') {
                    return 460
                } else {
                    return this.windowWidth > 1264 ? 1264 : '100%'
                }
            }
        },
        fileCount() {
            return this.fileAggregate?.files
        },
        projectFileCount() {
            return this.projectFileAggregate?.files
        },
    },
    data(){
        return{
            addUsersForm: {
                metadata: { users: [] },
                users: []
            },
            changeName: false,
            nameLoading: false,
            rules: {
                name: [
                    { required: true, message: this.$t('chat.field_require'), trigger: 'blur' },
                    { min: 3, max: 30, message: this.$t('chat.field_min_require', {min: 3}), trigger: 'blur' },
                ],
            },
            form: {
                name: ''
            },
            tab: 'members',
            fileAggregate: null,
            projectFileAggregate: null,
            tCount: 0
        }
    },
    watch: {
        activeChat(data) {
            if(!data) {
                this.visible = false
            }
        }
    },
    methods: {
        ...mapActions({
            getChatMembers: 'chat/getChatMembers',
        }),
        ...mapMutations({
            renameChat: "chat/RENAME_CHAT",
            deleteChatFromStore: 'chat/DELETE_CHAT'
        }),
        clearAddUserForm() {
            this.addUsersForm.metadata.users.splice(0)
            this.addUsersForm.users.splice(0)
        },
        changeMetadata({key, value}) {
            Vue.set(this.addUsersForm.metadata, key, value)
        },
        addUsers() {
            if (this.addUsersForm.users.length === 0)
                return 0

            const chat = {
                members: [],
                chat_uid: this.activeChat.chat_uid
            }

            const chatMembers = this.addUsersForm.users.map(user => ({ user: user.id, is_moderator: false }))
            chat.members.push(...chatMembers)

            if (this.addUsersForm.users.length > 1) {
                chat.is_public = true
                chat.name = this.chatName
            } else {
                chat.name = chat.members[0].user
            }

            try {
                this.$socket.client.emit('chat_add_user', chat)
                this.clearAddUserForm()
            } catch(error) {
                this.$message.error(this.$t('chat.failed_to_add_users'))
                console.error(error)
            }
        },
        afterVisibleChange(vis) {
            if(vis) {
                this.getFileCount()
                this.getTaskCount()
                if(this.projectId)
                    this.getProjectFileCount()
            } else {
                this.fileAggregate = null
                this.projectFileAggregate = null
                this.tCount = 0
                this.tab = 'members'
            }
        },
        changeChatName() {
            // if(this.activeChat.chat_author.id === this.user.id) {
            this.form.name = JSON.parse(JSON.stringify(this.activeChat.name))
            this.changeName = true
            // }
        },
        async getFileCount() {
            try {
                const { data } = await this.$http.get(`attachments/${this.activeChat.id}/aggregate/`)
                if(data)
                    this.fileAggregate = data
            } catch(e) {
                console.log(e)
            } 
        },
        async getProjectFileCount() {
            try {
                const { data } = await this.$http.get(`attachments/${this.projectId}/aggregate/`)
                if(data)
                    this.projectFileAggregate = data
            } catch(e) {
                console.log(e)
            }
        },
        async getTaskCount() {
            try {
                const { data } = await this.$http.get('/chat/task/list/', {
                    params: {
                        chat: this.activeChat.chat_uid,
                        task_type: 'task'
                    }
                })
                if(data)
                    this.tCount = data
            } catch(e) {
                console.log(e)
            }
        },
        submitForm() {
            this.$refs.nameForm.validate(async valid => {
                if (valid) {
                    try {
                        this.nameLoading = true
                        this.$socket.client.emit('chat_rename', {chat_uid: this.activeChat.chat_uid, chat_name: this.form.name})
                        this.renameChat({chat_uid: this.activeChat.chat_uid, chat_name: this.form.name})

                        this.changeName = false
                        this.$message.success(this.$t('chat.name_changed'))

                    } catch(e) {
                        this.$message.error(this.$t('chat.error') + e )
                    } finally {
                        this.nameLoading = false
                    }
                } else
                    return false
            })
        },
        showDeleteModal() {
            const self = this
            this.$confirm({
                title: this.$t('chat.chat_delete'),
                content: this.$t('chat.chat_delete_text'),
                okText: this.$t('chat.yes'),
                okType: 'danger',
                cancelText: this.$t('chat.no'),
                async onOk() {
                    await self.deleteChat()
                },
                onCancel() {
                    
                }
            })
        },
        async deleteChat() {
            try {
                this.$socket.client.emit('chat_delete', { chat_uid: this.activeChat.chat_uid });
                this.visible = false;
                this.deleteChatFromStore(this.activeChat.chat_uid);
                this.$message.success(this.$t('chat.deleted_success'));

                if (this.isMobile) {
                    this.$router.push({
                        name: 'chat-contact'
                    });
                } else {
                    this.$router.replace({ query: {} });
                }
            } catch (e) {
                this.$message.error(this.$t('chat.delete_error'));
            }
        },
        async getMembers($state) {
            try{
                this.loading = true
                if(!this.members || this.members.results.length !== this.members?.count) {
                    try {
                        const res = await this.getChatMembers({chat: this.activeChat.chat_uid})
                        if(res.next) {
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                    } catch(e) {
                        console.log(e)
                    }
                } else {
                    $state.complete()
                }
            }
            catch(e){

            }
            finally{
                this.loading = false
            }
        },

        addUser(){
            this.$store.commit('chat/SET_ADD_MEMBER_POPUP', true)
        },

    }
}
</script>

<style lang="scss">
.chat_info_drawer{
    .chat_members{
        .item{
            &:not(:first-child) {
                border-top: 1px solid var(--border2);
            }
        }
    }
    .ant-drawer-content-wrapper{
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .ant-drawer-header-no-title{
        display: none;
    }
    .ant-drawer-body{
        padding: 0px;
        overflow: hidden;
        height: 100%;
    }
    .drawer_tabs{
        .ant-tabs-bar{
            margin: 0px;
            .ant-tabs-tab{
                margin: 0px;
            }
        }
    }
    .drawer_header{
        border-bottom: 1px solid var(--border2);
        height: 50px;
        padding: 0 10px;
        .name{
            .truncate{
                max-width: 150px;
            }
        }
        .member{
            font-size: 12px;
            line-height: 13px;
            color: var(--gray);
        }
        .actions{
            display: flex;
            align-items: center;
        }
    }
    .drawer_body{
        height: calc(100% - 95px);
        overflow: hidden;
        .body_tabs{
            height: 100%;
            .ant-tabs-bar{
                display: none;
            }
            .ant-tabs-content{
                height: 100%;
            }
            .ant-tabs-tabpane{
                height: 100%;
                overflow-y: auto;
                &.padding_tab{
                    padding: 15px;
                }
            }
        }
    }
}
</style>