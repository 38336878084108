<template>
    <Gant 
        useProjects 
        :pageModel="pageModel"
        :page_name="page_name" />
</template>
  
<script>
import Gant from '@apps/UIModules/Gant/index.vue'
export default {
    components: {
        Gant
    },
    props: {
        page_name: {
            type: String,
            default: 'page_list_projects.WorkgroupModel'
        },
        pageModel: {
            type: String,
            default: 'workgroups.WorkgroupModel'
        }
    }
}
</script>
  