<template>
    <a-drawer
        :visible="drawerVisible"
        :title="drawerTitle"
        :width="drawerWidth"
        destroyOnClose
        @close="closeDrawer">
        <a-form-model 
            ref="form"
            :model="form"
            :rules="rules">
            <div class="form_block">
                <div class="form_block__header">
                    <h3>{{ $t("project.avatar_project") }}</h3>
                </div>
                <div class="flex items-center">
                    <Upload 
                        objectType
                        croper 
                        v-model="form.workgroup_logo">
                        <template v-slot:button>
                            <div class="flex items-center">
                                <a-avatar
                                    :size="60"
                                    :src="
                                        form.workgroup_logo && form.workgroup_logo.path
                                            ? form.workgroup_logo.path
                                            : null
                                    "
                                    :key="
                                        form.workgroup_logo && form.workgroup_logo.path
                                            ? form.workgroup_logo.path
                                            : null
                                    "
                                    flaticon
                                    icon="fi-rr-users-alt"/>
                                <div
                                    type="button"
                                    class="ml-6 ant-btn ant-btn-primary ant-btn-lg ant-btn-background-ghost flex items-center">
                                    <i class="flaticon fi fi-rr-cloud-upload-alt"></i>
                                    <span>{{ $t("project.logo_upload") }}</span>
                                </div>
                            </div>
                        </template>
                    </Upload>
                </div>
            </div>
            <div class="form_block">
                <div class="form_block__header">
                    <h3>{{ $t('Basic information about a project') }}</h3>
                </div>
                <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item :label="$t('project.title_project')" prop="name">
                        <a-input
                            v-model="form.name"
                            size="large"
                            :placeholder="$t('project.title_project')"/>
                    </a-form-model-item>
                    <a-form-model-item
                        :label="$t('Organization')"
                        prop="organization">
                        <DSelect
                            v-model="form.organization"
                            size="large"
                            apiUrl="/contractor_permissions/organizations/"
                            class="w-full"
                            oneSelect
                            :listObject="false"
                            labelKey="name"
                            :params="{ permission_type: 'create_workgroup' }"
                            :placeholder="$t('sports.selectFromList')"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"/>
                    </a-form-model-item>

                    <a-form-model-item
                        ref="locationRegion"
                        :label="$t('sports.region')"
                        prop="locationRegion">
                        <DSelect
                            v-model="form.locationRegion"
                            size="large"
                            apiUrl="/accounting_catalogs/locations/"
                            class="w-full"
                            :listObject="false"
                            :params="{ parent: 'root' }"
                            :placeholder="$t('sports.selectFromList')"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @change="changeSelect('region')"
                            @changeGetObject="changeGetObject">
                            <template v-slot:option_item="{ data }">
                                {{ data.code }} - {{ data.name }}
                            </template>
                        </DSelect>
                    </a-form-model-item>
                    <a-form-model-item
                        ref="locationDistrict"
                        :label="$t('sports.district')"
                        prop="locationDistrict">
                        <DSelect
                            v-model="form.locationDistrict"
                            size="large"
                            apiUrl="/accounting_catalogs/locations/"
                            class="w-full"
                            :key="form.locationRegion"
                            :disabled="form.locationRegion ? false : true"
                            :initList="isEdit ? true : false"
                            :listObject="false"
                            :params="{
                                parent: form.locationRegion,
                            }"
                            :placeholder="$t('sports.selectFromList')"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @change="changeSelect('district')"
                            @changeGetObject="changeGetObject">
                            <template v-slot:option_item="{ data }">
                                {{ data.code }} - {{ data.name }}
                            </template>
                        </DSelect>
                    </a-form-model-item>
                    <a-form-model-item
                        ref="location_akimat"
                        :label="$t('sports.akimat')"
                        prop="location_akimat">
                        <DSelect
                            v-model="form.location_akimat"
                            size="large"
                            apiUrl="/accounting_catalogs/locations/"
                            class="w-full"
                            :key="form.locationDistrict"
                            :disabled="form.locationDistrict ? false : true"
                            :initList="isEdit ? true : false"
                            :listObject="false"
                            :params="{ parent: form.locationDistrict }"
                            :placeholder="$t('sports.selectFromList')"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @change="changeSelect('akimat')"
                            @changeGetObject="changeGetObject">
                            <template v-slot:option_item="{ data }">
                                {{ data.code }} - {{ data.name }}
                            </template>
                        </DSelect>
                    </a-form-model-item>
                    <a-form-model-item
                        ref="location_settlement"
                        :label="$t('sports.settlement')"
                        prop="location_settlement">
                        <DSelect
                            v-model="form.location_settlement"
                            size="large"
                            apiUrl="/accounting_catalogs/locations/"
                            class="w-full"
                            :key="form.location_akimat"
                            :disabled="form.location_akimat ? false : true"
                            :initList="isEdit ? true : false"
                            :listObject="false"
                            :params="{
                                parent: form.location_akimat,
                            }"
                            :placeholder="$t('sports.selectFromList')"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @change="changeSelect('settlement')"
                            @changeGetObject="changeGetObject">
                            <template v-slot:option_item="{ data }">
                                {{ data.code }} - {{ data.name }}
                            </template>
                        </DSelect>
                    </a-form-model-item>
                    <a-form-model-item
                        ref="location"
                        :label="$t('sports.village')"
                        prop="location">
                        <DSelect
                            v-model="form.location"
                            size="large"
                            apiUrl="/accounting_catalogs/locations/"
                            class="w-full"
                            :key="form.location_settlement"
                            :disabled="form.location_settlement ? false : true"
                            :initList="isEdit ? true : false"
                            :listObject="false"
                            :params="{
                                parent: form.location_settlement,
                            }"
                            :placeholder="$t('sports.selectFromList')"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @changeGetObject="changeGetObject">
                            <template v-slot:option_item="{ data }">
                                {{ data.code }} - {{ data.name }}
                            </template>
                        </DSelect>
                    </a-form-model-item>
                    <a-form-model-item
                        ref="location_point"
                        :label="$t('sports.provide_address')"
                        prop="location_point">
                        <AddressSelect v-model="form.location_point" ref="addressSelect" />
                    </a-form-model-item>
                    <div v-if="form.location_point" class="address_item col-span-2">
                        <span>
                            {{ form.location_point.address }}    
                        </span>
                        <div class="flex items-center pl-2">
                            <a-button 
                                icon="fi-rr-edit" 
                                flaticon
                                @click="editAddress()" />
                            <a-button 
                                type="danger"
                                class="ml-1"
                                icon="fi-rr-trash" 
                                flaticon
                                @click="form.location_point = null" />
                        </div>
                    </div>

                    <a-form-model-item
                        class="col-span-2"
                        name="description"
                        prop="description"
                        :label="$t('project.description')">
                        <a-textarea
                            v-model="form.description"
                            size="large"
                            :auto-size="{ minRows: 4, maxRows: 7 }"/>
                    </a-form-model-item>
    
                </div>
            </div>
            <template v-if="!edit">
                <div class="form_block">
                    <div class="form_block__header">
                        <h3>{{ $t('Project template') }}</h3>
                    </div>
                    <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                        <a-checkbox v-model="form.use_template">{{ $t('Create a project based on a template') }}</a-checkbox>
                        <a-form-model-item class="col-span-2" ref="template" prop="template" :label="$t('Select project template')">
                            <DSelect
                                v-model="form.template"
                                size="large"
                                apiUrl="/work_groups/templates/available_temlates/"
                                class="w-full"
                                :key="form.template"
                                :listObject="false"
                                :placeholder="$t('Template')"
                                :default-active-first-option="false"
                                :filter-option="false"
                                :not-found-content="null">
                                <template v-slot:option_item="{ data }">
                                    {{ data.name }}
                                </template>
                            </DSelect>
                        </a-form-model-item>
                        
                    </div>
                </div>
            </template>
                    
            <div class="form_block">
                <div class="form_block__header">
                    <h3>{{ $t('Project dates') }}</h3>
                </div>
                <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item
                        prop="date_start_plan"
                        ref="date_start_plan"
                        :label="$t('project.date_start_plan')">
                        <a-date-picker
                            :locale="locale"
                            size="large"
                            class="w-full"
                            :show-time="true"
                            :showTime="{
                                defaultValue: $moment('09:00:00', 'HH:mm:ss'),
                            }"
                            @change="changeStartDate"
                            dropdownClassName="project_start"
                            :disabled-time="disabledDateTime"
                            :disabled-date="disabledDate"
                            @openChange="openStart"
                            format="DD-MM-YYYY HH:mm"
                            v-model="form.date_start_plan"/>
                    </a-form-model-item>

                    <a-form-model-item 
                        prop="dead_line" 
                        ref="dead_line" 
                        :label="$t('project.deadline_project')">
                        <a-date-picker 
                            :locale="locale" 
                            size="large" 
                            :disabled="fromTemplate"
                            class="w-full"
                            :show-time="true"
                            dropdownClassName="project_end"
                            :disabled-date="disabledDateFrom"
                            :showTime="{
                                defaultValue: $moment('18:00:00', 'HH:mm:ss')
                            }"
                            @openChange="openEnd"
                            format="DD-MM-YYYY HH:mm" 
                            v-model="form.dead_line"    />
                    </a-form-model-item>

                    <a-form-model-item class="col-span-2" prop="control_dates">
                        <a-checkbox v-model="form.control_dates">
                            {{ $t("project.control_dates") }}
                        </a-checkbox>
                        <a-alert :message="$t('project.project_control')" type="info" />
                    </a-form-model-item>

                </div>
            </div>
          
            <template v-if="false">
                <div class="form_block">
                    <div class="form_block__header">
                        <h3>Рабочее время</h3>
                    </div>
                    <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                        <a-form-model-item ref="funds" label="Рабочие дни" prop="funds">
                            <a-button size="large" class="mr-2" type="primary">Пн</a-button>
                            <a-button size="large" class="mr-2" type="primary">Вт</a-button>
                            <a-button size="large" class="mr-2" type="primary">Ср</a-button>
                            <a-button size="large" class="mr-2" type="primary">Чт</a-button>
                            <a-button size="large" class="mr-2">Сб</a-button>
                            <a-button size="large">Вс</a-button>
                        </a-form-model-item>
    
                        <a-form-model-item ref="funds" label="Рабочие часы" prop="funds">
                            <a-button size="large" class="mr-2" type="primary">9:00 - 13:00</a-button>
                            <a-button size="large" type="primary">14:00 - 18:00</a-button>
                        </a-form-model-item>
                    </div>
                </div>
            </template>

            <div class="form_block">
                <div class="form_block__header">
                    <h3>{{ $t('Planned project budget') }}</h3>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="funds" :label="$t('Project budget (million tenge)')" prop="funds">
                        <a-input-number
                            v-model="form.funds"
                            :max="Math.pow(10, 10)-1"
                            class="w-full"
                            :placeholder="$t('Project budget (million tenge)')"
                            size="large"/>
                    </a-form-model-item>
                </div>
            </div>
            <div class="footer_buttons">
                <!-- <a-button type="primary" size="large" :loading="loading" @click="formSubmit()">
                    {{ isEdit ? $t('invest.form.save_invest_project') : $t('invest.form.create_invest_project') }}
                </a-button> -->
                <a-button  
                    :loading="loadingBtn"  
                    class="mr-2"
                    type="primary" 
                    size="large"
                    @click="createProject()">
                    {{ submitButtonText }}
                </a-button>

                <a-button
                    class="mr-10"
                    type="primary"
                    ghost
                    size="large"
                    @click="closeDrawer">
                    {{ $t("invest.form.cancel") }}
                </a-button>

                <a-form-model-item prop="with_chat" class="mb-0">
                    <a-checkbox  v-model="form.with_chat">
                        {{$t('project.with_chat')}}
                    </a-checkbox>
                </a-form-model-item>
            </div>
        </a-form-model>
    </a-drawer>
</template>

<script>
import eventBus from "@/utils/eventBus"
import Upload from "@apps/Upload";
import DSelect from "@apps/DrawerSelect/Select.vue";
import AddressSelect from "@apps/DrawerSelect/AddressSelect";
import createdMethods from "./mixins/createdMethods";
import IMask from 'imask'
import locale from "ant-design-vue/es/date-picker/locale/ru_RU";
export default {
    name: 'NewProjectCreate',
    mixins: [createdMethods],
    components: {
        DSelect,
        Upload,
        AddressSelect,
    },
    props: {
        pageName: {
            type: String,
            default: 'page_list_project_workgroups.WorkgroupModel'
        },
    },
    data() {
        return {
            isEdit: false,
            dateFormat: 'YYYY-MM-DD HH:mm',

            locale,
            drawerVisible: false,
            form: {
                name: "",
                description: "",
                workgroup_logo: null,
                social_links: [],
                is_project: true,
                with_chat: false,
                dead_line: null,
                program: null,
                counterparty: null,
                costing_object: null,
                date_start_plan: null,
                control_dates: false,
                organization: null,
                template: '',
                use_template: false,
                selectedLocation: null,
                facility_type: null,
                facility_type3: null,
                facility_type2: null,
                location: null,
                is_countryside: false,
                locationRegion: null,
                locationDistrict: null,
                location_akimat: null,
                location_settlement: null,
                location_point: null,
                owner_name: "",
                owner_bin: "",
                ownership_form: null,
                purpose: null,
                purpose3: null,
                purpose2: null,
                building_year: null,
                area: "",
                bandwidth: null,
                storeys_number: null,
                funds: 0,
            },

            visible: false,
            loading: false,
            previewFile: null,
            edit: false,
            groupTypes: [],
            sLinks: [],
            listLinks: [],

            loadingBtn: false
        };
    },
    computed: {
        fromTemplate() {
            return this.form.use_template
        },
        rules() {
            return {
                name: [
                    { required: true, message: this.$t('project.field_require'), trigger: 'blur' },
                ],
                description: [{ required: true, message: this.$t('project.field_require'), trigger: 'blur' },],
                organization: [{ required: true, message: this.$t('project.field_require'), trigger: 'blur' },],
                dead_line: [{ required: !this.fromTemplate, message: this.$t('project.field_require'), trigger: 'change' },],
                date_start_plan: [{ required: this.fromTemplate, message: this.$t('project.field_require'), trigger: 'change' },],
                template: [{ required: this.fromTemplate, message: this.$t('project.field_require'), trigger: 'blur' },],
            }
        },
        submitButtonText() {
            return this.id ? this.$t('project.update') : this.$t('project.create')
        },
        id() {
            return this.$route.query.updateProject
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        drawerTitle() {
            return this.edit ? this.$t('project.update_project') : this.$t('project.add_project')
        },
        drawerWidth() {
            const baseWidth = 1100;
            const offset = 40;
            return this.windowWidth > baseWidth + offset
                ? baseWidth
                : this.windowWidth;
        },
        windowWidth() {
            return this.$store.state.windowWidth;
        },
    },
    watch: {
        "$route.query": {
            immediate: true,
            handler(query) {
                if (query.hasOwnProperty("create_project")) {
                    this.openDrawer();
                }
                if(query.updateProject){
                    this.init()
                    this.initUpdate()
                    this.openDrawer();
                }
            },
        },
    },
    methods: {
        editAddress() {
            this.$nextTick(() => {
                this.$refs.addressSelect.editAddress(this.form.location_point)
            })
        },
        async createProject() {
            this.$refs.form.validate(async v=>{
                if(v){
                    try{
                        this.loadingBtn = true
                        await this.uploadSocLink();
                        let res;

                        let form = {
                            ...this.form
                        }

                        if(form?.program?.id)
                            form['program'] = form.program.id
                        if(form?.counterparty?.id)
                            form['counterparty'] = form.counterparty.id
                        if(form?.costing_object?.id)
                            form['costing_object'] = form.costing_object.id
                        if(form.workgroup_logo?.id)
                            form['workgroup_logo'] = form.workgroup_logo
                        else
                            form['workgroup_logo'] = null
                        // if(form.organization)
                        //     form['organization'] = form.organization
                        // else
                        //     form['organization'] = null

                        if(this.id !== undefined){
                            form.name_ru = form.name
                            res = await this.updateGroupS({data: form, id: this.id})
                            this.$message.success(this.$t('project.information_edited'))
                        } else {
                            res = await this.createGroupS(form)
                            this.$message.success(this.$t('project.project_created'))
                        }


                        this.$refs.form.resetFields()
                        this.sLinks = []
                        eventBus.$emit('update_list_project')
                        eventBus.$emit(`table_row_${this.pageName}`, {
                            action: this.id !== undefined ? 'update' : 'create',
                            row: res
                        })
                        this.closeDrawer()
                        this.$router.replace({
                            query: {viewProject: res.id}
                        })
                    }
                    catch(error){
                        console.log(error)
                        if(error?.length)
                            this.$message.error(error.join(', '))
                        else
                            this.$message.error(this.$t('project.error') + error)
                    }
                    finally{
                        this.loadingBtn = false
                    }
                } else {
                    this.$message.error(this.$t('project.fill_all_fields'))
                }
            })
        },
        openStart(status) {
            this.$nextTick(() => {
                let mask = null;
                if (status) {
                    this.$nextTick(() => {
                        const input = document.querySelector(
                            ".project_start .ant-calendar-input"
                        );
                        if (input) {
                            mask = IMask(input, {
                                mask: Date,
                                pattern: this.dateFormat,
                                format: (date) => {
                                    return this.$moment(date).format(this.dateFormat);
                                },
                                parse: (str) => {
                                    return this.$moment(str, this.dateFormat);
                                },
                                blocks: {
                                    YYYY: {
                                        mask: IMask.MaskedRange,
                                        from: 1970,
                                        to: 2090,
                                    },
                                    MM: {
                                        mask: IMask.MaskedRange,
                                        from: 1,
                                        to: 12,
                                    },
                                    DD: {
                                        mask: IMask.MaskedRange,
                                        from: 1,
                                        to: 31,
                                    },
                                    HH: {
                                        mask: IMask.MaskedRange,
                                        from: 0,
                                        to: 23,
                                    },
                                    mm: {
                                        mask: IMask.MaskedRange,
                                        from: 0,
                                        to: 59,
                                    },
                                },
                            });
                        }
                    });
                } else mask = null;
            });
        },
        openEnd(status) {
            this.$nextTick(() => {
                let mask = null;
                if (status) {
                    this.$nextTick(() => {
                        const input = document.querySelector(
                            ".project_end .ant-calendar-input"
                        );
                        if (input) {
                            mask = IMask(input, {
                                mask: Date,
                                pattern: this.dateFormat,
                                format: (date) => {
                                    return this.$moment(date).format(this.dateFormat);
                                },
                                parse: (str) => {
                                    return this.$moment(str, this.dateFormat);
                                },
                                blocks: {
                                    YYYY: {
                                        mask: IMask.MaskedRange,
                                        from: 1970,
                                        to: 2090,
                                    },
                                    MM: {
                                        mask: IMask.MaskedRange,
                                        from: 1,
                                        to: 12,
                                    },
                                    DD: {
                                        mask: IMask.MaskedRange,
                                        from: 1,
                                        to: 31,
                                    },
                                    HH: {
                                        mask: IMask.MaskedRange,
                                        from: 0,
                                        to: 23,
                                    },
                                    mm: {
                                        mask: IMask.MaskedRange,
                                        from: 0,
                                        to: 59,
                                    },
                                },
                            });
                        }
                    });
                } else mask = null;
            });
        },
        disabledDateFrom(current) {
            if(this.form.date_start_plan) {
                if(this.$moment(this.form.date_start_plan).isSame(current.format(), 'day')) {
                    return false
                } else
                    return current && current < this.$moment(this.form.date_start_plan).endOf('day')
            } else
                return null
        },
        disabledDateTime() {
            if (this.form.dead_line) {
                return {
                    disabledHours: () =>
                        this.range(
                            this.$moment(this.form.dead_line)
                                .subtract({ hours: 1 })
                                .format("HH"),
                            24
                        ),
                };
            } else return null;
        },

        range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        },
        disabledDate(current) {
            if (this.form.dead_line)
                return (
                    current && current > this.$moment(this.form.dead_line).endOf("day")
                );
            else return null;
        },

        changeStartDate(date) {
            if (
                this.form.dead_line &&
        this.$moment(this.form.dead_line).isSameOrBefore(date)
            ) {
                this.form.date_start_plan = this.$moment(date).subtract({ hours: 1 });
            }
        },
        changeGetObject(obj) {
            this.form.selectedLocation = obj;
        },
        changeSelect(type) {
            switch (type) {
            case "region":
                this.form.locationDistrict = null;
                this.form.location_akimat = null;
                this.form.location_settlement = null;
                this.form.location = null;
                break;
            case "district":
                this.form.location_akimat = null;
                this.form.location_settlement = null;
                this.form.location = null;
                break;
            case "akimat":
                this.form.location_settlement = null;
                this.form.location = null;
                break;
            case "settlement":
                this.form.location = null;
                break;
            }
        },

        removeQueryParam() {
            const query = Object.assign({}, this.$route.query);
            if (query.hasOwnProperty("create_project")) {
                delete query.create_project;
                this.$router.replace({ query });
            }
            if(query.updateProject) {
                const viewGroup = query.updateProject
                delete query['updateProject']
                query.viewGroup = viewGroup
            }
            this.edit = false
            this.$router.replace({query})
        },
        openDrawer() {
            this.drawerVisible = true;
        },
        closeDrawer() {
            this.drawerVisible = false;
            this.removeQueryParam();
        },
    },
};
</script>

<style lang="scss" scoped>
.form_block {
  padding: 15px;
  border: 1px solid var(--border2);
  border-radius: var(--borderRadius);
  margin-bottom: 20px;

  @media (min-width: 768px) {
    padding: 30px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h3 {
      font-size: 20px;
      color: #000000;
      font-weight: 400;
      margin: 0px;
    }

    .st {
      color: #000000;
      font-size: 18px;
      opacity: 0.3;
      padding-left: 15px;
      text-wrap: nowrap;
    }
  }

  &__attachments {
    margin-bottom: 20px;
  }
}

.footer_buttons {
  display: flex;
  align-items: center;

  &::v-deep {
    .ant-btn {
      //   &:not(:last-child) {
      //     margin-right: 10px;
      //   }

      &.ant-btn-lg {
        height: 50px;
        padding: 0 25px;
      }
    }
  }
}

.address_item{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e1e7ec;
    padding: 15px;
    border-radius: 8px;
}
</style>