export default {
    "gantt": {
        "toggleAside": "Сол жақ панельді жасыру/көрсету",
        "hours": "Сағат",
        "days": "Күн",
        "weeks": "Апта",
        "months": "Ай",
        "quarters": "Тоқсан",
        "years": "Жыл",
        "today": "Бүгін",
        "project_start": "Жобаның басталуы",
        "project_end": "Жобаның аяқталу мерзімі",
        "error": "Қате орын алды",
        "taskMinTime": "Тапсырманың минималды ұзақтығы — 10 минут.",
        "task": "Тапсырма",
        "projectTask": "Жоба/Тапсырма",
        "startDate": "Басталу күні",
        "endDate": "Аяқталу күні",
        "duration": "Ұзақтық",
        "status": "Статус",
        "fStartDate": "Басталу күні",
        "fEndDate": "Мерзімдік аяқталу күні",
        "currentMonth": "Ағымдағы ай",
        "currentQuarters": "Ағымдағы тоқсан",
        "currentYear": "Ағымдағы жыл",
        "add_task": "Тапсырма қосу"
    }
}