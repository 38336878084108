<template>
    <div 
        class="sprint_card" 
        :class="visible && 'active'">
        <div class="sprint_card__header">
            <div 
                class="flex items-center pr-2 cursor-pointer" 
                @click="openSprint(!visible)">
                <a-button 
                    type="link" 
                    flaticon 
                    class="flex items-center justify-center card_arrow"
                    style="font-size: 22px;"
                    shape="circle"
                    icon="fi-rr-angle-small-down" />
                <div class="sprint_status ml-3" :style="`background: ${statusColor}`">
                    {{ $t(`task.${sprint.status}`) }}
                </div>
                <div class="card_info ml-7">
                    <div class="card_name mb-1">{{ sprint.name }}</div>
                    <div class="flex items-center card_dates">
                        <div class="mr-4">
                            {{ $moment(sprint.created_at).format('DD MMM') }}
                            <span v-if="sprint.dead_line">
                                - {{ $moment(sprint.dead_line).format('DD MMM') }}
                            </span>
                        </div>
                        <div>
                            Задач: {{ taskCount }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-end">
                <div class="count_stat mr-4">
                    <div 
                        v-tippy="{ inertia : true, duration : '[600,300]'}"
                        content="Новые задачи"
                        class="count_stat__item new">
                        {{ sprint.new_task_count }}
                    </div>
                    <div 
                        v-tippy="{ inertia : true, duration : '[600,300]'}"
                        content="Задачи в процессе"
                        class="count_stat__item process">
                        {{ sprint.in_work_task_count }}
                    </div>
                    <div 
                        v-tippy="{ inertia : true, duration : '[600,300]'}"
                        content="Завершенные задачи"
                        class="count_stat__item completed">
                        {{ sprint.completed_task_count }}
                    </div>
                </div>
                <a-button type="primary" ghost size="large">
                    Аналитика
                </a-button>
                <a-dropdown :trigger="['click']">
                    <a-button 
                        type="primary"
                        ghost 
                        flaticon
                        icon="fi-rr-angle-small-down"
                        class="flex items-center ml-2"
                        size="large">
                        Еще
                    </a-button>
                    <a-menu slot="overlay">
                        <a-menu-item 
                            key="share" 
                            class="flex items-center"
                            @click="share()">
                            <i class="fi fi-rr-share mr-2"></i> {{$t('task.share_to_chat')}}
                        </a-menu-item>
                        <template v-if="isAuthor" >
                            <a-menu-item 
                                key="edit" 
                                class="flex items-center" 
                                @click="edit()">
                                <i class="fi fi-rr-edit mr-2"></i> {{$t('task.edit')}}
                            </a-menu-item>
                            <a-menu-divider />
                            <a-menu-item 
                                class="text-red-500 flex items-center" 
                                key="delete" 
                                @click="deleteSprint()">
                                <i class="fi fi-rr-trash mr-2"></i> {{$t('task.remove')}}
                            </a-menu-item>
                        </template>
                    </a-menu>
                </a-dropdown>
                <a-button 
                    v-if="isAuthor && sprint.status !== 'completed'" 
                    type="primary" 
                    class="ml-2"
                    size="large"
                    :loading="loading"
                    @click="actionHandler()">
                    <template v-if="sprint.status === 'new'">
                        Запустить спринт
                    </template>
                    <template v-if="sprint.status=== 'in_process'">
                        Завершить спринт
                    </template>
                </a-button>
            </div>
        </div>
        <div v-if="visible" class="sprint_card__body">
            <div v-if="sprint.target || sprint.expected_result.length" class="sprint_target mb-3">
                <div v-if="sprint.target" class="sprint_target__item">Цель: {{ sprint.target }}</div>
                <div v-if="sprint.expected_result && sprint.expected_result.length" class="sprint_target__item">Ожидаемый результат: {{ sprint.expected_result.join(', ') }}</div>
            </div>
            <a-spin 
                :spinning="taskLoading" 
                class="w-full sprint_table">
                <template v-if="!empty">
                    <a-table 
                        :columns="columns" 
                        :showHeader="false"
                        :pagination="false"
                        :scroll="{ x: 1200 }"
                        :locale="{
                            emptyText: $t('task.no_data')
                        }"
                        :row-key="(record, index) => `${record.id}-${index}`"
                        :data-source="taskList">
                        <div slot="id" slot-scope="text, record">
                            <TaskAction :item="record" :user="user" />
                        </div>
                        <div slot="counter" slot-scope="text, record" class="cursor-pointer" @click="openTask(record)">
                            #{{ text }}
                        </div>
                        <div slot="name" slot-scope="text, record" class="cursor-pointer task_table_name blue_color" @click="openTask(record)">
                            {{ text }}
                        </div>
                        <div slot="organization" slot-scope="text, record">
                            {{ record.organization ? record.organization.name : '' }}
                        </div>
                        <div slot="owner" slot-scope="text, record">
                            <Profiler 
                                :user="record.owner" 
                                initStatus
                                :subtitle="{text: $t('task.owner')}" />
                        </div>
                        <div slot="operator" slot-scope="text, record">
                            <template v-if="user && record.is_auction">
                                <div class="text-gray mb-1">
                                    {{ $t('task.operator') }}
                                </div>
                                <a-popconfirm
                                    :title="$t('task.handler.confirmTakeTask')"
                                    :ok-text="$t('task.yes')"
                                    :cancel-text="$t('task.no')"
                                    @confirm="takeTask(record)">
                                    <a-button
                                        type="primary"
                                        ghost
                                        class="flex items-center"
                                        :loading="takeLoader">
                                        <i class="fi fi-rr-user-add mr-2"></i>
                                        {{ $t('task.handler.ok') }}
                                    </a-button>
                                </a-popconfirm>
                            </template>
                            <Profiler
                                v-else 
                                :user="record.operator" 
                                initStatus
                                :subtitle="{
                                    text: $t('task.operator')
                                }" />
                        </div>
                        <div slot="created_at" slot-scope="text, record">
                            {{$moment(record.created_at).format('DD.MM.YYYY в HH:mm')}}
                        </div>
                        <div slot="dead_line" slot-scope="text, record">
                            <DeadLine 
                                :taskStatus="record.status" 
                                :date="record.dead_line" />
                        </div>
                        <div slot="status" slot-scope="text, record">
                            <TaskStatus :status="record.status" class="table_status" />
                        </div>
                    </a-table>
                    <div class="flex justify-end mt-2">
                        <a-pagination
                            :current="page"
                            :page-size.sync="pageSize"
                            :defaultPageSize="Number(pageSize)"
                            :total="count"
                            hideOnSinglePage
                            show-less-items
                            @change="changePage">
                            <template slot="buildOptionText" slot-scope="props">
                                {{ props.value }}
                            </template>
                        </a-pagination>
                    </div>
                </template>
                <a-empty v-if="empty">
                    <template #description>
                        Задачи отсутствуют
                    </template>
                </a-empty>
            </a-spin>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import DeadLine from '../DeadLine.vue'
import TaskStatus from '../TaskStatus'
import TaskAction from './TaskAction'
export default {
    components: {
        DeadLine,
        TaskStatus,
        TaskAction
    },
    props: {
        sprint: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            sprintDetail: null,
            loading: false,
            taskList: [],
            count: 0,
            pageSize: 10,
            taskLoading: false,
            page: 1,
            empty: false,
            takeLoader: false,
            page_name: `sprint_tasks_${this.sprint.id}`,
            columns: [
                {
                    dataIndex: 'id',
                    key: 'id',
                    scopedSlots: { customRender: 'id' },
                    width: 50
                },
                {
                    dataIndex: 'counter',
                    key: 'counter',
                    scopedSlots: { customRender: 'counter' },
                    width: 120
                },
                {
                    dataIndex: 'name',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    dataIndex: 'organization',
                    key: 'organization',
                    scopedSlots: { customRender: 'organization' },
                },
                {
                    dataIndex: 'owner',
                    key: 'owner',
                    scopedSlots: { customRender: 'owner' },
                },
                {
                    dataIndex: 'operator',
                    key: 'operator',
                    scopedSlots: { customRender: 'operator' },
                },
                {
                    dataIndex: 'created_at',
                    key: 'created_at',
                    scopedSlots: { customRender: 'created_at' },
                    width: 170
                },
                {
                    dataIndex: 'dead_line',
                    key: 'dead_line',
                    scopedSlots: { customRender: 'dead_line' },
                    width: 160
                },
                {
                    dataIndex: 'status',
                    key: 'status',
                    scopedSlots: { customRender: 'status' },
                    width: 120
                }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isAuthor() {
            if(this.$store.state.user.user?.id === this.sprint.author.id)
                return true
            else
                return false
        },
        taskCount() {
            return this.sprint.completed_task_count + this.sprint.in_work_task_count + this.sprint.new_task_count
        },
        statusColor() {
            switch (this.sprint.status) {
            case "new":
                return '#1D65C0'
                break;
            case "in_process":
                return '#722ed1'
                break;
            case "completed":
                return '#52c41a'
                break;
            default:
                return '#1D65C0'
            }
        }
    },
    methods: {
        async openTask(item) {
            const query = Object.assign({}, this.$route.query)
            if(query.task && Number(query.task) !== item.id || !query.task) {
                query.task = item.id
                this.$router.push({query})
            }
        },
        async takeTask(task) {
            try {
                this.takeLoader = true
                await this.$store.dispatch('task/takeAuctionTask', { task })
                this.$message.success(this.$t('task.handler.success'))
                this.getTaskList()
            } catch(error) {
                this.$message.error(this.$t('task.handler.error'))
                console.error(error)
            } finally {
                this.takeLoader = false
            }
        },
        changePage(page) {
            this.page = page
            this.getTaskList()
        },
        openSprint(vis) {
            if(vis) {
                this.getTaskList()
            } else {
                this.taskList = []
                this.count = 0
                this.empty = false
            }
            this.visible = vis
        },
        async getTaskList() {
            try {
                this.taskLoading = true
                const { data } = await this.$http.get(`/tasks/sprint/${this.sprint.id}/tasks_list/`, {
                    params: {
                        page_size: this.pageSize,
                        page: this.page,
                        page_name: this.page_name
                    }
                })
                if(data) {
                    this.taskList = data.results
                    this.count = data.count
                    if(this.page === 1 && data.count === 0)
                        this.empty = true
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.taskLoading = false
            }
        },
        share() {
            this.$store.commit('share/SET_SHARE_PARAMS', { 
                model: 'tasks.TaskModel',
                shareId: this.sprint.id,
                object: this.sprint,
                shareUrl: `${window.location.origin}/ru/dashboard?sprint=${this.sprint.id}`,
                shareTitle: `Спринт - ${this.sprint.name}`
            })
        },
        async actionHandler() {
            if(this.sprint.status === 'new') {
                try {
                    this.loading = true
                    await this.$http.put(`tasks/sprint/${this.sprint.id}/update_status/`, {status: 'in_process'})
                    this.$message.success("Спринт начат")
                    eventBus.$emit('update_filter_tasks.TaskSprintModel')
                } catch(e) {
                    console.log(e)
                    this.$message.error(this.$t('error'))
                } finally {
                    this.loading = false
                }
            }
            if(this.sprint.status === 'in_process') {
                try {
                    this.loading = true
                    await this.$http.put(`tasks/sprint/${this.sprint.id}/update_status/`, {status: 'completed'})
                    this.$message.success("Спринт завершен")
                    eventBus.$emit('update_filter_tasks.TaskSprintModel')
                } catch(e) {
                    this.$message.error(this.$t('error'))
                } finally {
                    this.loading = false
                }
            }
        },
        edit() {
            eventBus.$emit('edit_sprint', this.sprint)
        },
        deleteSprint() {
            this.$confirm({
                title: 'Вы действительно хотите удалить спринт?',
                closable: true,
                maskClosable: true,
                cancelText: 'Отмена',
                okText: 'Удалить',
                okType: 'danger',
                zIndex: 99999,
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post('/table_actions/update_is_active/', [{ id: this.sprint.id, is_active: false }])
                            .then(() => {
                                this.$message.success('Спринт удален')
                                eventBus.$emit('update_filter_tasks.TaskSprintModel')
                                resolve()
                            })
                            .catch((e) => {
                                console.log(e)
                                reject()
                            })
                    })
                }
            })
        }
    },
    mounted() {
        eventBus.$on(`update_task_list_${this.sprint.id}`, () => {
            this.page = 1
            this.getTaskList()
        })
    },
    beforeDestroy() {
        eventBus.$off(`update_task_list_${this.sprint.id}`)
    }
}
</script>

<style lang="scss" scoped>
.task_table_name{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.3s;
    word-break: break-word;
}
.sprint_table{
    &::v-deep{
        .table_status{
            height: 30px;
            line-height: 30px;
            padding-left: 15px;
            padding-right: 15px;
            border-radius: 30px;
        }
        .ant-table-row{
            td{
                border-color: #ced3fb;
            }
        }
    }
}
.sprint_card{
    background: #FAFAFA;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 15px;
    color: #000;
    &.active{
        .card_arrow{
            transform: rotate(180deg);
        }
    }
    .sprint_target{
        &__item{
            opacity: 0.6;
            &:not(:last-child){
                margin-bottom: 5px;
            }
        }
    }
    &__body{
        border-top: 1px solid #afafaf;
        margin-top: 20px;
        padding-top: 20px;
    }
    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .count_stat{
        display: flex;
        align-items: center;
        &__item{
            height: 40px;
            width: 40px;
            color: #000;
            font-size: 14px;
            line-height: 40px;
            text-align: center;
            border-radius: 6px;
            &:not(:last-child) {
                margin-right: 5px;
            }
            &.new{
                background: #ced3fb;
            }
            &.process{
            background: #efbdbd;
            }
            &.completed{
                background: #bdf0cc;
            }
        }
    }
    .sprint_status{
        background: #1D65C0;
        border-radius: 30px;
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        color: #fff;
        line-height: 35px;
        text-align: center;
        min-width: 112px;
    }
    .card_name{
        font-size: 18px;
        line-height: 22px;
    }
    .card_dates{
        opacity: 0.6;
        font-size: 14px;
    }
}
</style>