import Vue from 'vue'
//import taskRouters from '@apps/vue2TaskComponent/config/router.js'
//import businessRoutes from '@apps/BusinessProcesses/config/router.js'
//import notificationsRoutes from '@apps/Notifications/config/router.js'
import ordersRoutes from '@apps/Orders/config/router.js'
import investProjectRoutes from '@apps/InvestProject/config/router.js'
import sportsFacilitiesRoutes from '@apps/SportsFacilities/config/router.js'
import projectsRoutes from '@apps/Projects/config/router.js'
// import { Trans } from '@/config/Translation'
//import { v4 as uuidv4, validate as uuidValidate } from 'uuid'
import { message } from 'ant-design-vue'
import { checkNewPageWidget } from '@/utils/routerUtils'
export default {
    SET_ROUTE_INFO(state, data) {
        Vue.set(state.routeInfo, data.name, data)
    },
    SET_ROUTER_INIT(state, value) {
        let routerList = []
        for (const name in value) {
            const route = {
                ...value[name],
                component: checkNewPageWidget(value[name]),
                name,
                path: name,
                meta: {
                    ...value[name]
                }
            }

            if(route.name === 'chat') {
                Vue.set(route, 'badge', true)
                route.children = [
                    {
                        name: 'chat-body',
                        path: '/chat/:id',
                        component: checkNewPageWidget(value[name])
                    }
                ]
            }
            if(route.name === 'projects') {
                route.children = projectsRoutes
                route.redirect = { name: 'projects-list' }
            }
            
            routerList.push(route)
        }
        if(routerList?.length) {
            routerList = routerList.concat(ordersRoutes).concat(investProjectRoutes).concat(sportsFacilitiesRoutes)

            routerList = routerList.sort(function (a, b) {
                return a.descOrder - b.descOrder
            })
            const routerListMobile = JSON.parse(JSON.stringify(routerList)).sort(function (a, b) {
                return a.mobileOrder - b.mobileOrder
            })

            state.routerApp = routerList

            state.routerMobile = [...routerListMobile].filter(f => !f.isHidden && !f.hideMobile)
            state.routerList = [...routerList].filter(f => !f.isHidden)
        }
    },
    CHANGE_ROUTE_MOBILE_FOOTER(state, value) {
        if(value.removed) {
            const route = value.removed.element
            const index = state.routerMobile.findIndex(f => f.name === route.name)
            if(index !== -1)
                Vue.set(state.routerMobile[index], 'isFooter', false)
        }
        if(value.added) {
            const route = value.added.element
            const footerRoutes = state.routerMobile.filter(f => f.isFooter && f.isShowMobile)
            if(footerRoutes.length >= 4) {
                message.warning({content: 'Максимум 4 модуля'})
            } else {
                const index = state.routerMobile.findIndex(f => f.name === route.name)
                if(index !== -1) {
                    Vue.set(state.routerMobile[index], 'isFooter', true)
                }
            }
        }
    },
    CHANGE_ROUTE_MOBILE_DEACTIVATE(state, value) {
        if(value.removed) {
            const route = value.removed.element
            const index = state.routerMobile.findIndex(f => f.name === route.name)
            if(index !== -1)
                Vue.set(state.routerMobile[index], 'isShowMobile', true)
        }
        if(value.added) {
            const route = value.added.element
            const index = state.routerMobile.findIndex(f => f.name === route.name)
            if(index !== -1) {
                Vue.set(state.routerMobile[index], 'isShowMobile', false)
            }
        }
    },
    CHANGE_ROUTE_SHOW(state, { value, route }) {
        return new Promise((resolve, reject) => {
            const index = state.routerList.findIndex(f => f.name === route.name)
            if(index !== -1)
                Vue.set(state.routerList[index], 'isShow', value)

            resolve(true)
        })
    },
    DELETE_ROUTE_MOBILE(state, route) {
        const index = state.routerMobile.findIndex(f => f.name === route.name)
        if(index !== -1)
            Vue.set(state.routerMobile[index], 'isFooter', false)
    },
    CHANGE_ROUTER_LIST(state, value) {
        state.routerList = value
    },
    CHANGE_MOBILE_ROUTER_LIST(state, value) {
        state.routerMobile = value
    },
    ADD_ROUTE(state, value) {
        state.addRoute.push(value)
    },
    SET_MENU_COUNTER(state, { value, name }) {
        Vue.set(state.counterLink, name, value)
    },
    INCREMENT_MENU_COUNTER(state, name) {
        if (typeof state.counterLink?.[name] !== "undefined") {
            state.counterLink[name]++
        }
    },
    CLEAR_MENU_COUNTER(state, name) {
        if (typeof state.counterLink?.[name] !== "undefined") {
            state.counterLink[name] = 0
        }
    },
    SET_MENU(state, value) {
        /*
        state.menu = value.map(item => {
            let route = {
                ...item,
                icon: replaceIcon(item)
            }

            if (item.children?.length) {
                route['children'] = item.children.map(c => {
                    let child = {
                        ...c,
                        icon: replaceIcon(c)
                    }

                    if (c.children?.type === 'import') {
                        if (c.children.config === 'task') {
                            Vue.set(child, 'children', taskRouters({meta: c.meta, state: this.state}).filter(f => !f.meta.hideSidebar))
                            child.redirect = { name: 'task-list-page' }
                            child.meta.sidebarCollapse = true
                        }
                    }

                    if (child.meta?.badgeCounter)
                        Vue.set(state.counterLink, child.name, 0)

                    return child
                })
            }

            return route
        })*/
    },
    PAGE_ROUTE_GENERATE(state, list) {
        /*
        let routersList = list

        routersList = list.map(item => {
            let route = {
                ...item
            }

            if (item.children?.length) {
                route['component'] = checkCascadeWidget()
                route['children'] = item.children.map(c => {
                    let child = {
                        ...c,
                        component: checkPageWidget(c)
                    }

                    if (c.children?.type === 'import') {
                        if (c.children.config === 'task') {
                            Vue.set(child, 'children', taskRouters({meta: c.meta, state: this.state}))
                            child.redirect = { name: 'task-list-page' }
                            child.beforeEnter = (to, from, next) => {
                                if (to.name === 'tasks')
                                    return next({ name: 'task-list-page' })
                                else
                                    return next()
                            }
                            child.meta.sidebarCollapse = true
                        }
                        if (c.children.config === 'businessProcesses') {
                            Vue.set(child, 'children', businessRoutes)
                            child.redirect = { name: 'business_processes_main' }
                            child.beforeEnter = (to, from, next) => {
                                if (to.name === 'business_processes')
                                    return next({ name: 'business_processes_main' })
                                else
                                    return next()
                            }
                        }

                    }

                    return child
                })
            } else {
                delete route.children
                route['component'] = checkPageWidget(item)
            }

            return route
        })

        routersList = routersList.concat(notificationsRoutes)
        routersList = routersList.concat(ordersRoutes)

        state.appRoute = routersList
        */
    },
    FORM_ROUTE_GENERATE(state, list) {
        /*const cRoute = []

        list.forEach(p => {
            if (p?.children?.length) {
                p.children.forEach(c => {
                    if (c?.meta?.pageConfig?.formInfo?.length) {
                        c.meta.pageConfig.formInfo.forEach(f => {
                            cRoute.push({
                                name: f.name,
                                path: `${f.name}/:action/:id`,
                                component: checkFormWidget(f),
                                beforeEnter: (to, from, next) => {
                                    const { params, meta: { parent } } = to

                                    if (params.action === 'create' || params.action === 'update') {
                                        if (params.action === 'create') {
                                            if (uuidValidate(params.id))
                                                return next()
                                            else
                                                return next({ name: parent.name, params: { lang: Trans.currentLanguage } })
                                        } else {
                                            if (uuidValidate(params.id)) {
                                                return next()
                                            } else {
                                                return next({ name: parent.name, params: { lang: Trans.currentLanguage } })
                                            }
                                        }
                                    } else
                                        return next({ name: parent.name, params: { lang: Trans.currentLanguage } })
                                },
                                meta: {
                                    ...f,
                                    parent: c
                                }
                            })
                        })
                    }
                })
            }
        })

        state.appRoute.push({
            path: 'form',
            component: () => import('@/views/Dashboard/FormCascade'),
            children: cRoute
        })*/
    },
    /*TOGGLE_SETTING(store, { value, type }) {
        store[type] = value
    },*/
    TOGGLE_ACTIVE_MENU(state, value) {
        state.activeMenu = value
    },
    PUSH_HEADER_BTN_ROUTER(state, value) {
        state.pushRoutes.push(value)
    }
}