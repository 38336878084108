<template>
    <ModuleWrapper :pageTitle="pageTitle">
        <template v-if="!isMobile" v-slot:h_left>
            <PageFilter
                :model="model"
                :key="page_name"
                class="mr-2"
                size="large"
                :page_name="page_name" />
        </template>
        <template v-if="!isMobile" v-slot:h_right>
            <a-button
                type="primary" 
                icon="plus"
                class="mr-2"
                size="large"
                @click="newInquir()">
                {{ $t('inquiries.resAdd') }}
            </a-button>
        </template>
        <component
            :is="listComponent"
            :page_name="page_name"
            :name="page_name"
            :model="model"
            class="w-full"
            showPageTitle
            showHeader
            showFilter
            showAddButton
            :newInquir="newInquir">
        </component>
        <NewInquir :pageName="page_name" />
    </ModuleWrapper>
</template>

<script>
import NewInquir from './components/NewInquir'
import PageFilter from '@/components/PageFilter'
import eventBus from '@/utils/eventBus'
import ModuleWrapper from '@/components/ModuleWrapper/index.vue'
export default {
    name: 'InquiriesIndex',
    components: {
        ModuleWrapper,
        PageFilter,
        NewInquir,
    },
    data() {
        return {
            page_name: 'citizen_inquiries_list',
            model: 'risk_assessment.RiskAssessmentModel'
        }
    },
    mounted() {
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        listComponent() {
            if(this.isMobile)
                return () => import(/* webpackMode: "lazy" */'./components/MobileList.vue')
            else
                return () => import(/* webpackMode: "lazy" */'./components/List.vue')
        },
        pageTitle() {
            return this.$route?.meta?.title || ''
        },
    },
    methods: {
        newInquir() {
            eventBus.$emit('new_inquir')
        }
    }
}
</script>
