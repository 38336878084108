<template>
    <Drawer 
        v-model="visible" 
        :title="edit ? $t('wgr.update_group') : $t('wgr.create_group')" 
        :width="500" 
        class="group_create_drawer"
        :class="isMobile && 'mobile'"
        destroyOnClose
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false" >
        <div slot="body">
            <a-skeleton  active :paragraph="{ rows: 4 }" :loading="loading"/>
            <div v-show="!loading">
                <a-form-model 
                    :model="form" 
                    ref="form" 
                    :rules="rules">
                    <a-form-model-item 
                        :label="$t('wgr.avatar_group')">
                        <div class="flex items-center">
                            <label for="wrg_avatar" class="cursor-pointer">
                                <a-avatar 
                                    :size="60" 
                                    :src="form.workgroup_logo && form.workgroup_logo.path ? form.workgroup_logo.path : null"
                                    :key="form.workgroup_logo && form.workgroup_logo.path ? form.workgroup_logo.path : null"
                                    flaticon
                                    icon="fi-rr-users-alt" />
                            </label>
                            <label for="wrg_avatar" class="cursor-pointer ml-2 ant-btn ant-btn-dashed flex items-center">
                                <i class="flaticon fi fi-rr-cloud-upload-alt"></i>
                                <span>{{ $t('wgr.logo_upload') }}</span>
                            </label>
                        </div>
                        <input
                            type="file"
                            id="wrg_avatar"
                            style="display:none;"
                            ref="wrg_avatar"
                            v-on:change="handleFileChange"
                            accept=".jpg, .jpeg, .png, .gif" />
                    </a-form-model-item>
                    <a-form-model-item 
                        prop="name" 
                        :label="$t('wgr.title_group')">
                        <a-input 
                            v-model="form.name" 
                            size="large"  />
                    </a-form-model-item>
                    <a-form-model-item 
                        name="description" 
                        prop="description" 
                        :label="$t('wgr.description')">
                        <a-textarea
                            v-model="form.description"
                            :auto-size="{ minRows: 2, maxRows: 7 }" />
                    </a-form-model-item>
                    <a-form-model-item  ref="workgroup_type" :label="$t('wgr.group_type')">
                        <a-select v-model="form.workgroup_type" size="large" placeholder="Тип группы"  >
                            <a-select-option v-for="item in groupTypes" :key="item.id" :value="item.id" >
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item  prop="public_or_private" class="mt-2" >
                        <a-checkbox  v-model="form.public_or_private">
                            {{$t('wgr.closed')}}
                        </a-checkbox>
                    </a-form-model-item>
                    <a-form-model-item  prop="with_chat" class="-mt-2" >
                        <a-checkbox  v-model="form.with_chat">
                            {{$t('wgr.with_chat')}}
                        </a-checkbox>
                    </a-form-model-item>
                </a-form-model>
            </div>
            <a-drawer
                title=""
                placement="right"
                :width="cropDrawerWidth"
                :zIndex="99999"
                destroyOnClose
                class="cropper_modal"
                :visible="cropModal"
                @close="closeCropModal()">
                <div class="cr_d_body">
                    <div v-if="dataUrl" class="relative h-full">
                        <img
                            ref="avatarImg"
                            @load.stop="createCropper"
                            :src="dataUrl" />

                        <div class="action_btn flex items-center">
                            <a-button 
                                type="ui"
                                icon="fi-rr-rotate-left" 
                                flaticon
                                shape="circle"
                                @click="cropper.rotate(-45)" />
                            <a-button 
                                type="ui"
                                class="ml-1" 
                                flaticon
                                shape="circle"
                                icon="fi-rr-rotate-right"
                                @click="cropper.rotate(45)"  />
                        </div>
                    </div>
                </div>
                <div class="cr_d_footer">
                    <a-button type="primary" size="large" block @click="uploadImage()" class="mb-2" :loading="uploadLoading">
                        {{$t('wgr.upload')}}
                    </a-button>
                    <a-button type="ui" ghost block size="large" @click="closeCropModal()">
                        {{$t('close')}}
                    </a-button>
                </div>
            </a-drawer>
        </div>
        <div slot="footer">
            <a-button 
                :loading="loadingBtn" 
                type="primary"
                class="px-6"
                :block="isMobile"
                :size="isMobile ? 'large' : 'default'"
                @click="createGroup()">
                {{  id === undefined ?  $t('wgr.create'):
                    $t('wgr.update')}} </a-button>
        </div>
    </Drawer>
</template>

<script>
import Drawer from './widgets/DrawerTemplate'
import createdMethods from './mixins/createdMethods'
import eventBus from "@/utils/eventBus"
import 'cropperjs/dist/cropper.css'
export default {
    name: "GroupsAndProjectsCreateGroup",
    mixins: [createdMethods],
    props: {
        buttonSize: {
            type: String,
            default: 'default'
        },
        pageName: {
            type: String,
            default: 'page_list_workgroup_workgroups.WorkgroupModel'
        },
    },
    components: {
        Drawer
    },
    data(){
        return{
            form: {
                name: "",
                description: "",
                workgroup_type: "",
                public_or_private: false,
                workgroup_logo: null,
                with_chat: false,
                social_links: [],
                program: null,
                counterparty: null,
                costing_object: null
            },
            visible: false,
            loading: false,
            previewFile: null,
            edit: false,
            rules: {
                name: [
                    { required: true, message: this.$t('wgr.field_require'), trigger: 'blur' },
                ],
                description: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' },],
                workgroup_type: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' },]
            },
            groupTypes: [],
            sLinks: [],
            listLinks: [],

            loadingBtn: false
        }
    },
    async created(){
        this.getParamsQuerySet()
    },
    watch: {
        async '$route.query' (){
            // this.replaceRoute();
            this.getParamsQuerySet()

        },
    },
    computed:{
        id(){
            return   this.$route.query.updateGroup
        },
        isMobile() {
            return this.$store.state.isMobile
        }
        // showCreateRelations() {
        //     if(this.$store.state.config.config?.groups_and_projects?.hideCreateRelations)
        //         return false
        //     return true
        // }

    },
    methods: {
        afterVisibleChange(vis) {
            if(!vis) {
                this.clearAll()
            }
        },
        getParamsQuerySet(){
            const query =  Object.assign({}, this.$route.query)

            if(query.hasOwnProperty('createGroup')){
                this.init()

            }
            if(query.updateGroup){
                this.init()
                this.initUpdate()
            }
        },
        clearAll(){
            this.form = {
                name: "",
                description: "",
                workgroup_type: "",
                public_or_private: false,
                workgroup_logo: null,
                with_chat: false,
                social_links: [],
                program: null,
                counterparty: null,
                costing_object: null
            }
            this.close()
        },
        close(){
            const query =  Object.assign({}, this.$route.query)
            delete query['createGroup']
            if(query.updateGroup) {
                const viewGroup = query.updateGroup
                delete query['updateGroup']
                query.viewGroup = viewGroup
            }
            this.edit = false
            this.$router.replace({query})
        },
        async createGroup(){
            this.$refs.form.validate(async v=>{
                if(v){
                    try{
                        this.loadingBtn = true
                        await this.uploadSocLink();
                        setTimeout(async () => {
                            let res;

                            let form = {
                                ...this.form
                            }

                            if(form?.program?.id)
                                form['program'] = form.program.id
                            if(form?.counterparty?.id)
                                form['counterparty'] = form.counterparty.id
                            if(form?.costing_object?.id)
                                form['costing_object'] = form.costing_object.id
                            if(form.workgroup_logo?.id)
                                form['workgroup_logo'] = form.workgroup_logo.id
                            else
                                form['workgroup_logo'] = null
                            if(form.organization?.id)
                                form['organization'] = form.organization.id
                            else
                                form['organization'] = null

                            if(this.id !== undefined){
                                form.name_ru = form.name
                                res =   await   this.updateGroupS({data: form, id: this.id})
                                this.$message.success(this.$t('wgr.information_edited'))
                            } else {
                                res =   await   this.createGroupS(form)
                                this.$message.success(this.$t('wgr.group_created'))
                            }

                            this.$refs.form.resetFields();
                            this.sLinks = [];
                            this.visible = false
                            eventBus.$emit('update_list_group')
                            eventBus.$emit(`table_row_${this.pageName}`, {
                                action: this.id !== undefined ? 'update' : 'create',
                                row: res
                            })
                            this.$router.replace({
                                query: {viewGroup: res.id}
                            });
                        }, 1000);
                    }
                    catch(error){
                        this.$message.error(this.$t('wgr.error') + error)
                    }
                    finally{
                        setTimeout(() => {
                            this.loadingBtn = false
                        }, 1000);

                    }
                } else {
                    this.$message.error(this.$t('wgr.fill_all_fields'))
                }
            })

        },

    }
}
</script>

<style lang="scss" scoped>
.cropper_modal{
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
        }
        .cropper-face{
            border-radius: 50%;
        }
        .cropper-view-box {
            border-radius: 50%;
        }
        .ant-drawer-header-no-title{
            display: none;
        }
        .ant-drawer-body{
            height: 100%;
            padding: 0px;
        }
        .cr_d_body{
            height: calc(100% - 100px);
        }
        .action_btn{
            position: absolute;
            bottom: 10px;
            right: 15px;
        }
        .cr_d_footer{
            height: 100px;
            border-top: 1px solid var(--border1);
            padding: 5px 15px;
        }
    }
}
.group_create_drawer{
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
        }
        .ant-drawer-body{
            padding: 0px;
            height: calc(100% - 40px);
        }
        .drawer_body{
            padding: 15px;
            height: calc(100% - 40px);
            overflow-y: auto;
        }
        .drawer_footer{
            display: flex;
            align-items: center;
            padding-left: 15px;
            padding-right: 15px;
            border-top: 1px solid var(--border2);
            height: 40px;
        }
    }
    &.mobile{
        &::v-deep{
            .drawer_body{
                height: calc(100% - 50px);
            }
            .drawer_footer{
                height: 50px;
                & > div{
                    width: 100%;
                }
            }
        }
    }
}
</style>